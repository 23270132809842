export const ChiefComplaintKeys = {
  asymptomatic: "asymptomatic",
  diarrhea: "diarrhea",
  analMass: "analMass",
  obstruction: "obstruction",
  vomiting: "vomiting",
  bleeding: "bleeding",
  tenesmus: "tenesmus",
  abdominalPain: "abdominalPain",
  generalFatigue: "generalFatigue",
  abdominalDistention: "abdominalDistention",
  mucusDischarge: "mucusDischarge",
  changeOfStoolSize: "changeOfStoolSize",
  abdominalMass: "abdominalMass",
  apptiteLoss: "apptiteLoss",
  anemia: "anemia",
  constipation: "constipation",
  analPain: "analPain",
  occultBloodTest: "occultBloodTest",
  nausea: "nausea",
};
export const ChiefComplaintOptions = [
  {
    label: "Asymptomatic",
    value: false,
    key: ChiefComplaintKeys.asymptomatic,
  },
  {
    label: "Mucus discharge",
    value: false,
    key: ChiefComplaintKeys.mucusDischarge,
  },
  {
    label: "Diarrhea",
    value: false,
    key: ChiefComplaintKeys.diarrhea,
  },
  {
    label: "Change of stool size",
    value: false,
    key: ChiefComplaintKeys.changeOfStoolSize,
  },
  {
    label: "Anal mass",
    value: false,
    key: ChiefComplaintKeys.analMass,
  },
  {
    label: "Abdominal mass",
    value: false,
    key: ChiefComplaintKeys.abdominalMass,
  },
  {
    label: "Obstruction",
    value: false,
    key: ChiefComplaintKeys.obstruction,
  },
  {
    label: "Apptite Loss",
    value: false,
    key: ChiefComplaintKeys.apptiteLoss,
  },
  {
    label: "Vomiting",
    value: false,
    key: ChiefComplaintKeys.vomiting,
  },
  {
    label: "Anemia",
    value: false,
    key: ChiefComplaintKeys.anemia,
  },
  {
    label: "Bleeding",
    value: false,
    key: ChiefComplaintKeys.bleeding,
  },
  {
    label: "Constipation",
    value: false,
    key: ChiefComplaintKeys.constipation,
  },
  {
    label: "Tenesmus",
    value: false,
    key: ChiefComplaintKeys.tenesmus,
  },
  {
    label: "Anal pain",
    value: false,
    key: ChiefComplaintKeys.analPain,
  },
  {
    label: "Abdominal pain",
    value: false,
    key: ChiefComplaintKeys.abdominalPain,
  },
  {
    label: "Occult blood test",
    value: false,
    key: ChiefComplaintKeys.occultBloodTest,
  },
  {
    label: "General fatigue",
    value: false,
    key: ChiefComplaintKeys.generalFatigue,
  },
  {
    label: "Nausea",
    value: false,
    key: ChiefComplaintKeys.nausea,
  },
  {
    label: "Abdominal distention",
    value: false,
    key: ChiefComplaintKeys.abdominalDistention,
  },
];

export const CoMorbidDiseaseKeys = {
  none: "none",
  dm: "dm",
  ht: "ht",
  cva: "cva",
  dyslipidemia: "dyslipidemia",
  cardiacDisease: "cardiacDisease",
  crf: "crf",
  steroidUsage: "steroidUsage",
  immunodeficiency: "immunodeficiency",
  anticoagulant: "anticoagulant",
  other: "other",
  // cardiac disease
  ischemicHeartDisease: "ischemicHeartDisease",
  valvularHeartDisease: "valvularHeartDisease",
  cardiacArrhythmia: "cardiacArrhythmia",
  // anticoagulant
  warfarinStopDate: "warfarinStopDate",
  aspirinStopDate: "aspirinStopDate",
  clopidogrelStopDate: "clopidogrelStopDate",
  anticoagulantOther: "anticoagulantOther",
};

export const CoMorbidDiseaseOptions = [
  {
    label: "None",
    value: false,
    key: CoMorbidDiseaseKeys.none,
  },
  {
    label: "DM",
    value: false,
    key: CoMorbidDiseaseKeys.dm,
  },
  {
    label: "HT",
    value: false,
    key: CoMorbidDiseaseKeys.ht,
  },
  {
    label: "CVA",
    value: false,
    key: CoMorbidDiseaseKeys.cva,
  },
  {
    label: "Dyslipidemia",
    value: false,
    key: CoMorbidDiseaseKeys.dyslipidemia,
  },
  {
    label: "Cardiac disease",
    value: false,
    key: CoMorbidDiseaseKeys.cardiacDisease,
  },
  {
    label: "CRF",
    value: false,
    key: CoMorbidDiseaseKeys.crf,
  },
  {
    label: "Steroid usage",
    value: false,
    key: CoMorbidDiseaseKeys.steroidUsage,
  },
  {
    label: "Immunodeficiency",
    value: false,
    key: CoMorbidDiseaseKeys.immunodeficiency,
  },
  {
    label: "Anticoagulant/ antiplatelet drugs usage",
    value: false,
    key: CoMorbidDiseaseKeys.anticoagulant,
  },
  {
    label: "Others",
    value: false,
    key: CoMorbidDiseaseKeys.other,
  },
];

export const CardiacDiseaseOptions = [
  {
    label: "Ischemic heart disease",
    value: false,
    key: CoMorbidDiseaseKeys.ischemicHeartDisease,
  },
  {
    label: "Valvular heart disease",
    value: false,
    key: CoMorbidDiseaseKeys.valvularHeartDisease,
  },
  {
    label: "Cardiac arrhythmia",
    value: false,
    key: CoMorbidDiseaseKeys.cardiacArrhythmia,
  },
];

export const FamilialColorectalCancersKey = {
  firstDegree: "firstDegree",
  secondDegree: "secondDegree",
  thirdDegree: "thirdDegree",
};

export const FamilialColorectalCancersOptions = [
  {
    label: "First degree (พ่อแม่ พี่น้อง ลูก)",
    value: false,
    key: FamilialColorectalCancersKey.firstDegree,
  },
  {
    label: "Second degree (ปู่ย่า ตายาย ลุงป้า น้าอา หลาน)",
    value: false,
    key: FamilialColorectalCancersKey.secondDegree,
  },
  {
    label: "Third degree (ญาติคนอื่น ๆ)",
    value: false,
    key: FamilialColorectalCancersKey.thirdDegree,
  },
];

export const CancerStatusEnum = { normal: "NORMAL", hideCancel: "HIDE_CANCEL" };

export const CancerStatusOptions = [
  { label: "---- ตัวเลือก ----", value: null },
  { label: "Normal", value: CancerStatusEnum.normal },
  {
    label: "Hide/Cancel",
    value: CancerStatusEnum.hideCancel,
  },
];
