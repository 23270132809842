export const DetailOfVascularLigationKey = {
  ileocolicArtery: "ileocolicArtery",
  rightColicArtery: "rightColicArtery",
  middleColicArtery: "middleColicArtery",
  leftColicArtery: "leftColicArtery",
  sigmoidalArtery: "sigmoidalArtery",
};
export const DetailOfVascularLigationOptions = [
  {
    label: "Ileocolic artery",
    value: false,
    key: DetailOfVascularLigationKey.ileocolicArtery,
  },
  {
    label: "Right colic artery",
    value: false,
    key: DetailOfVascularLigationKey.rightColicArtery,
  },
  {
    label: "Middle colic artery",
    value: false,
    key: DetailOfVascularLigationKey.middleColicArtery,
  },
  {
    label: "Left colic artery",
    value: false,
    key: DetailOfVascularLigationKey.leftColicArtery,
  },
  {
    label: "Sigmoidal artery",
    value: false,
    key: DetailOfVascularLigationKey.sigmoidalArtery,
  },
];

export const RectalTransactionKey = {
  stapler: "stapler",
  contour: "contour",
  transectionAndPurgeStringSuture: "transectionAndPurgeStringSuture",
  isr: "isr",
};
export const RectalTransactionOptions = [
  {
    label: "Stapler",
    value: false,
    key: RectalTransactionKey.stapler,
  },
  {
    label: "Contour",
    value: false,
    key: RectalTransactionKey.contour,
  },
  {
    label: "Transection and purge string suture",
    value: false,
    key: RectalTransactionKey.transectionAndPurgeStringSuture,
  },
  {
    label: "ISR",
    value: false,
    key: RectalTransactionKey.isr,
  },
];

export const StaplerEnum = {
  johnson: "JOHNSON",
  convidien: "CONVIDIEN",
  sureform: "SUREFORM",
};

export const IsrKey = {
  tranabdominal: "tranabdominal",
  tranperineum: "tranperineum",
  tranperinealTme: "tranperinealTme",
};
export const IsrOptions = [
  {
    label: "Tranabdominal",
    value: false,
    key: IsrKey.tranabdominal,
  },
  {
    label: "Tranperineum",
    value: false,
    key: IsrKey.tranperineum,
  },
  {
    label: "Tranperineal TME",
    value: false,
    key: IsrKey.tranperinealTme,
  },
];

export const LevelOfSphincterExcisionKey = {
  atDentateLine: "atDentateLine",
  belowDentateLine: "belowDentateLine",
  totalSphincterResection: "totalSphincterResection",
};
export const LevelOfSphincterExcisionOption = [
  {
    label: "At dentate line",
    value: false,
    key: LevelOfSphincterExcisionKey.atDentateLine,
  },
  {
    label: "Below dentate line",
    value: false,
    key: LevelOfSphincterExcisionKey.belowDentateLine,
  },
  {
    label: "Total sphincter resection",
    value: false,
    key: LevelOfSphincterExcisionKey.totalSphincterResection,
  },
];
