import { observable, makeAutoObservable, action } from "mobx";
import dayjs from "dayjs";
import {
  OperativeNote as OperativeNoteModel,
  OperativeNoteIsInvalid as OperativeNoteIsInvalidModel,
  MalignancyCase as MalignancyCaseModel,
  MalignancyCaseIsInvalid as MalignancyCaseIsInvalidModel,
} from "../models/colorectoralSurgeries/operativeNotes/OperativeNote";
import { Surgeon as SurgeonModel } from "../models/colorectoralSurgeries/operativeNotes/Surgeon";
import {
  Assistant as AssistantModel,
  AssistantIsInvalid as AssistantIsInvalidModel,
} from "../models/colorectoralSurgeries/operativeNotes/Assistant";
import { Transfusion as TransfusionModel } from "../models/colorectoralSurgeries/operativeNotes/Transfusion";
import {
  Operation as OperationModel,
  OperationIsInvalid as OperationIsInvalidModel,
} from "../models/colorectoralSurgeries/operativeNotes/Operation";
import { MalignantFinding as MalignantFindingModel } from "../models/colorectoralSurgeries/operativeNotes/MalignantFinding";
import {
  ColonicResection as ColonicResectionModel,
  RectalResectionIsInvalid as RectalResectionIsInvalidModel,
  RectalResection as RectalResectionModel,
} from "../models/colorectoralSurgeries/operativeNotes/Resection";
import {
  Ligation as LigationModel,
  LigationIsInvalid as LigationIsInvalidModel,
} from "../models/colorectoralSurgeries/operativeNotes/Ligation";
import { Margin as MarginModel } from "../models/colorectoralSurgeries/operativeNotes/Margin";
import {
  OrganResection as OrganResectionModel,
  OrganResectionIsInvalid as OrganResectionIsInvalidModel,
} from "../models/colorectoralSurgeries/operativeNotes/OrganResection";
import { AnastomosisSection as AnastomosisSectionModel } from "../models/colorectoralSurgeries/operativeNotes/AnastomosisSection";
import { Ostomy as OstomyModel } from "../models/colorectoralSurgeries/operativeNotes/Ostomy";
import { WoundAndDrainage as WoundAndDrainageModel } from "../models/colorectoralSurgeries/operativeNotes/WoundAndDrainage";
import { BleedingCheck as BleedingCheckModel } from "../models/colorectoralSurgeries/operativeNotes/BleedingCheck";
import { IntraOperativeComplication as IntraOperativeComplicationModel } from "../models/colorectoralSurgeries/operativeNotes/IntraoperativeComplication";
// Constant
import { ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant } from "../constants/Form";
import { InitialDropdown as InitialDropdownConstant } from "../constants/Form";
import { InitialUser as InitialUserConstant } from "../constants/User";
import { OperativeSettingEnum as OperativeSettingEnumConstant } from "../constants/colorectalSurgery/operativeNotes/OperativeNote";
import { LocationOptions as LocationOptionsConstant } from "../constants/colorectalSurgery/operativeNotes/Diagnosis";
import { SynchronousLesionOptions as SynchronousLesionOptionsConstant } from "../constants/colorectalSurgery/operativeNotes/SynchronousLesion";
import { AnesthesiaOptions as AnesthesiaOptionsConstant } from "../constants/colorectalSurgery/operativeNotes/Anesthesia";
import {
  OperativeProcedureKey as OperativeProcedureKeyConstant,
  OperativeProcedureOptions as OperativeProcedureOptionsConstant,
  PelvicExenterationOptions as PelvicExenterationOptionsConstant,
  SacrectomyOptions as SacrectomyOptionsConstant,
  SacrectomyExtendedOptions as SacrectomyExtendedOptionsConstant,
  PositionOptions as PositionOptionsConstant,
  TechniqueOptions as TechniqueOptionsConstant,
  ConversionOpenOptions as ConversionOpenOptionsConstant,
} from "../constants/colorectalSurgery/operativeNotes/Operation";
import { MalignancyCaseTreatmentIntentionOptions as MalignancyCaseTreatmentIntentionOptionsConstant } from "../constants/colorectalSurgery/operativeNotes/OperativeNote";
import { LiverMetastatectomyOptions as LiverMetastatectomyOptionsConstant } from "../constants/colorectalSurgery/operativeNotes/MalignantFinding";
import { SeedingSiteOptions as SeedingSiteOptionsConstant } from "../constants/colorectalSurgery/operativeNotes/MalignantFinding";
import {
  ResectionOptions as ResectionOptionsConstant,
  MiddleColicArteryOptions as MiddleColicArteryOptionsConstant,
  PelvicLymphNodeDissectionOptions as PelvicLymphNodeDissectionOptionsConstant,
  IliacVesselsResectionOptions as IliacVesselsResectionOptionsConstant,
} from "../constants/colorectalSurgery/operativeNotes/Resection";
import {
  DetailOfVascularLigationOptions as DetailOfVascularLigationOptionsConstant,
  RectalTransactionKey as RectalTransactionKeyConstant,
  RectalTransactionOptions as RectalTransactionOptionsConstant,
  IsrOptions as IsrOptionsConstant,
  LevelOfSphincterExcisionOption as LevelOfSphincterExcisionOptionConstant,
  RectalTransactionKey,
} from "../constants/colorectalSurgery/operativeNotes/Ligation";
import {
  EnBlocResectionOfAdjacentOrgansKey as EnBlocResectionOfAdjacentOrgansKeyConstant,
  EnBlocResectionOfAdjacentOrgansOptions as EnBlocResectionOfAdjacentOrgansOptionsConstant,
  DiaphragmOptions as DiaphragmOptionsConstant,
  StomachOptions as StomachOptionsConstant,
  DuodenumOptions as DuodenumOptionsConstant,
  SmallIntestineOptions as SmallIntestineOptionsConstant,
  PancreasOptions as PancreasOptionsConstant,
  KidneyOptions as KidneyOptionsConstant,
  AdrenalGlandOptions as AdrenalGlandOptionsConstant,
  UreterOptions as UreterOptionsConstant,
  UrinaryBladderOptions as UrinaryBladderOptionsConstant,
  UterusOptions as UterusOptionsConstant,
  OvaryOptions as OvaryOptionsConstant,
  VaginaOptions as VaginaOptionsConstant,
  ProstateOptions as ProstateOptionsConstant,
  PelvicFloorOptions as PelvicFloorOptionsConstant,
  SacrectomyOptions as OrganResectionSacrectomyOptionsConstant,
  UrinaryBladderReconstructionDetailOptions as UrinaryBladderReconstructionDetailOptionsConstant,
  EnBlocResectionOfAdjacentOrgansKey,
} from "../constants/colorectalSurgery/operativeNotes/OrganResection";
import {
  AnastomoticTypeOptions as AnastomoticTypeOptionsConstant,
  AnastomoticTechniqueOptions as AnastomoticTechniqueOptionsConstant,
  AnastomosisOptions as AnastomosisOptionsConstant,
  DistalRectalWashoutOptions as DistalRectalWashoutOptionsConstant,
  AirLeakTestOptions as AirLeakTestOptionsConstant,
} from "../constants/colorectalSurgery/operativeNotes/AnastomosisSection";
import {
  OstomySiteOptions as OstomySiteOptionsConstant,
  OstomyTypeOptions as OstomyTypeOptionsConstant,
  OstomyOpeningOptions as OstomyOpeningOptionsConstant,
  OstomyMaturationsOptions as OstomyMaturationsOptionsConstant,
} from "../constants/colorectalSurgery/operativeNotes/Ostomy";
import { SurgicalWoundClassificationOptions as SurgicalWoundClassificationOptionsConstant } from "../constants/colorectalSurgery/operativeNotes/BleedingCheck";
// Utils
import { ScrollToElementId as ScrollToElementIdUtil } from "../utils/ScrollToElement";
import { CalculateTotalDateTimeHourMin as CalculateTotalDateTimeHourMinUtil } from "../utils/FormatDate";
import { GetOperativeNoteById as GetOperativeNoteByIdService } from "../services/colorectals/OperativeNote";

class OperativeNoteStore {
  private initialOperativeNote: OperativeNoteModel = {
    id: "",
    referenceNo: "",
    operativeDate: "",
    operativeSetting: "",
    colorectalSurgery: { ...InitialDropdownConstant },
    emergencyDetail: { ...InitialDropdownConstant },
    synchronousLesionDetail: "",
    otherOperativeDetail: "",
    files: [],
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
  };
  private initialOperativeNoteIsInvalid: OperativeNoteIsInvalidModel = {
    operativeSetting: false,
    emergencyDetail: false,
  };
  private initialDiagnosisLocations = [...LocationOptionsConstant];
  private initialSynchronousLesions = [...SynchronousLesionOptionsConstant];
  private initialSurgeons: SurgeonModel = {
    surgeonOne: { ...InitialUserConstant },
    surgeonOneType: { ...InitialDropdownConstant },
    surgeonTwo: { ...InitialUserConstant },
    surgeonTwoType: { ...InitialDropdownConstant },
    surgeonThree: { ...InitialUserConstant },
    surgeonThreeType: { ...InitialDropdownConstant },
  };
  private initialAssistants: AssistantModel = {
    assistantOne: { ...InitialUserConstant },
    assistantOneType: { ...InitialDropdownConstant },
    assistantTwo: { ...InitialUserConstant },
    assistantTwoType: { ...InitialDropdownConstant },
    assistantThree: { ...InitialUserConstant },
    assistantThreeType: { ...InitialDropdownConstant },
    otherAssistant: "",
    anesthesiologist: "",
    scrubNurse: "",
    operativeStartAt: "",
    operativeStopAt: "",
    totalOperativeTime: "",
    bloodLoss: "",
  };
  private initialAssistantIsInvalid: AssistantIsInvalidModel = {
    anesthesiologist: false,
    operativeStartAt: false,
    operativeStopAt: false,
  };
  private initialAnesthesia = [...AnesthesiaOptionsConstant];
  private initialTransfusion: TransfusionModel = {
    prc: 0,
    ffp: 0,
    platelet: 0,
  };
  private initialOperativeProcedures = [...OperativeProcedureOptionsConstant];
  private initialOperation: OperationModel = {
    abdominoperinealResectionValue: { ...InitialDropdownConstant },
    otherProcedureDetail: "",
  };
  private initialOperationIsInvalid: OperationIsInvalidModel = {
    abdominoperinealResectionValue: false,
    pelvicExenteration: false,
    sacrectomy: false,
    sacrectomyExtended: false,
    otherProcedureDetail: false,
  };
  private initialPelvicExenteration = [...PelvicExenterationOptionsConstant];
  private initialSacrectomy = [...SacrectomyOptionsConstant];
  private initialSacrectomyExtended = [...SacrectomyExtendedOptionsConstant];
  private initialPosition = [...PositionOptionsConstant];
  private initialTechnique = [...TechniqueOptionsConstant];
  private initialConversionToOpen = [...ConversionOpenOptionsConstant];
  private initialMalignancyCase: MalignancyCaseModel = {
    malignancyCase: "true",
  };
  private initialMalignancyCaseIsInvalid: MalignancyCaseIsInvalidModel = {
    malignancyCase: false,
    treatmentIntention: false,
  };
  private initialMalignancyCaseTreatmentIntention = [
    ...MalignancyCaseTreatmentIntentionOptionsConstant,
  ];
  private initialMalignantFinding: MalignantFindingModel = {
    liverMetastasis: "",
    ascites: "",
    ascitesAmount: "",
    peritonealSeeding: "",
    otherQuadrantsDetail: "",
  };
  private initialLiverMetastatectomy = [...LiverMetastatectomyOptionsConstant];
  private initialSeedingSite = [...SeedingSiteOptionsConstant];
  private initialResection = [...ResectionOptionsConstant];
  private initialColonicResection: ColonicResectionModel = {
    completeMesocolicExcision: "",
    qualityOfCmeSpecimen: "",
  };
  private initialMiddleColicArtery = [...MiddleColicArteryOptionsConstant];
  private initialRectalResection: RectalResectionModel = {
    totalMesorectalExcision: { ...InitialDropdownConstant },
    splenicFlexureMobilization: "",
    dissectionPlaneOfCircumferential: { ...InitialDropdownConstant },
    qualityOfTmeSpecimen: "",
    pelvicAutonomicNerve: { ...InitialDropdownConstant },
    pelvicAutonomicNerveDetail: "",
  };
  private initialRectalResectionIsInvalid: RectalResectionIsInvalidModel = {
    splenicFlexureMobilization: false,
  };
  private initialPelvicLymphNodeDissection = [
    ...PelvicLymphNodeDissectionOptionsConstant,
  ];
  private initialIliacVesselsResection = [
    ...IliacVesselsResectionOptionsConstant,
  ];
  private initialLigation: LigationModel = {
    centralVascularLigation: "",
    imaLigation: { ...InitialDropdownConstant },
    imvLigation: { ...InitialDropdownConstant },
    staplerValue: "",
    staplerPiece: 0,
  };
  private initialLigationIsInvalid: LigationIsInvalidModel = {
    staplerValue: false,
    staplerPiece: false,
    isr: false,
    levelOfSphincterExcision: false,
  };
  private initialDetailOfVascularLigations = [
    ...DetailOfVascularLigationOptionsConstant,
  ];
  private initialRectalTransactions = [...RectalTransactionOptionsConstant];
  private initialIsr = [...IsrOptionsConstant];
  private initialLevelOfSphincterExcision = [
    ...LevelOfSphincterExcisionOptionConstant,
  ];
  private initialMargin: MarginModel = {
    distalMargin: 0,
    proximalMargin: 0,
    grosslyMargin: { ...InitialDropdownConstant },
  };
  private initialOrganResection: OrganResectionModel = {
    adjacentOrgansInvasion: "",
    otherOrganDetail: "",
    vaginalReconstruction: { ...InitialDropdownConstant },
    vaginalReconstructionDetail: "",
    palvicFloorDefectReconstruction: { ...InitialDropdownConstant },
    palvicFloorDefectReconstructionDetail: "",
    closureOfPerinealWound: { ...InitialDropdownConstant },
    closureOfPerinealWoundDetail: "",
  };
  private initialOrganResectionIsInvalid: OrganResectionIsInvalidModel = {
    diaphragm: false,
    stomach: false,
    duodenum: false,
    smallIntestine: false,
    pancreas: false,
    kidney: false,
    adrenalGland: false,
    ureter: false,
    urinaryBladder: false,
    uterus: false,
    ovary: false,
    vagina: false,
    prostate: false,
    pelvicFloor: false,
    sacrectomy: false,
    otherOrganDetail: false,
  };
  private initialEnBlocResectionOfAdjacentOrgans = [
    ...EnBlocResectionOfAdjacentOrgansOptionsConstant,
  ];
  private initialDiaphragms = [...DiaphragmOptionsConstant];
  private initialStomachs = [...StomachOptionsConstant];
  private initialDuodenums = [...DuodenumOptionsConstant];
  private initialSmallIntestines = [...SmallIntestineOptionsConstant];
  private initialPancreases = [...PancreasOptionsConstant];
  private initialKidney = [...KidneyOptionsConstant];
  private initialAdrenalGlands = [...AdrenalGlandOptionsConstant];
  private initialUreters = [...UreterOptionsConstant];
  private initialUrinaryBladders = [...UrinaryBladderOptionsConstant];
  private initialUteruses = [...UterusOptionsConstant];
  private initialOvaries = [...OvaryOptionsConstant];
  private initialVaginas = [...VaginaOptionsConstant];
  private initialProstates = [...ProstateOptionsConstant];
  private initialPelvicFloors = [...PelvicFloorOptionsConstant];
  private initialOrganResectionSacrectomy = [
    ...OrganResectionSacrectomyOptionsConstant,
  ];
  private initialUrinaryBladderReconstructionDetails = [
    ...UrinaryBladderReconstructionDetailOptionsConstant,
  ];
  private initialAnastomosisSection: AnastomosisSectionModel = {
    linearStaple: "",
    circularStaple: { ...InitialDropdownConstant },
    repairBy: { ...InitialDropdownConstant },
    repairByDetail: "",
  };
  private initialAnastomoticTypes = [...AnastomoticTypeOptionsConstant];
  private initialAnastomoticTechniques = [
    ...AnastomoticTechniqueOptionsConstant,
  ];
  private initialAnastomosis = [...AnastomosisOptionsConstant];
  private initialDistalRectalWashouts = [...DistalRectalWashoutOptionsConstant];
  private initialAirLeakTests = [...AirLeakTestOptionsConstant];
  private initialOstomy: OstomyModel = {
    ostomy: false,
    option: "",
  };
  private initialOstomySites = [...OstomySiteOptionsConstant];
  private initialOstomyTypes = [...OstomyTypeOptionsConstant];
  private initialOstomyOpenings = [...OstomyOpeningOptionsConstant];
  private initialOstomyMaturations = [...OstomyMaturationsOptionsConstant];
  private initialWoundAndDrainage: WoundAndDrainageModel = {
    abdominalCavityDrainage: "",
    abdominalCavityDrainageDetail: "",
    perinealWoundDrainage: "",
    perinealWoundDrainageDetail: "",
  };
  private initialBleedingCheck: BleedingCheckModel = {
    bleedingCheck: false,
    stoppedAt: "",
    rectalTubeInsertion: "",
    spillageOfBowelContent: "",
  };
  private initialSurgicalWoundClassifications = [
    ...SurgicalWoundClassificationOptionsConstant,
  ];
  private initialIntraOperativeComplication: IntraOperativeComplicationModel = {
    intraoperative: "",
    intraoperativeDetail: "",
  };

  @observable isReadOnly: boolean = false;
  @observable operativeNote: OperativeNoteModel = {
    ...this.initialOperativeNote,
  };
  @observable operativeNoteIsInvalid: OperativeNoteIsInvalidModel = {
    ...this.initialOperativeNoteIsInvalid,
  };
  @observable diagnosisLocations = [...this.initialDiagnosisLocations];
  @observable synchronousLesions = [...this.initialSynchronousLesions];
  @observable surgeons: SurgeonModel = { ...this.initialSurgeons };
  @observable assistants: AssistantModel = { ...this.initialAssistants };
  @observable assistantIsInvalid: AssistantIsInvalidModel = {
    ...this.initialAssistantIsInvalid,
  };
  @observable anesthesia = [...this.initialAnesthesia];
  @observable transfusion = { ...this.initialTransfusion };
  @observable operativeProcedures = [...this.initialOperativeProcedures];
  @observable operation = { ...this.initialOperation };
  @observable operationIsInvalid = { ...this.initialOperationIsInvalid };
  @observable pelvicExenteration = [...this.initialPelvicExenteration];
  @observable sacrectomy = [...this.initialSacrectomy];
  @observable sacrectomyExtended = [...this.initialSacrectomyExtended];
  @observable position = [...this.initialPosition];
  @observable technique = [...this.initialTechnique];
  @observable conversionToOpen = [...this.initialConversionToOpen];
  @observable malignancyCase: MalignancyCaseModel = {
    ...this.initialMalignancyCase,
  };
  @observable malignancyCaseIsInvalid: MalignancyCaseIsInvalidModel = {
    ...this.initialMalignancyCaseIsInvalid,
  };
  @observable malignancyCaseTreatmentIntention = [
    ...this.initialMalignancyCaseTreatmentIntention,
  ];
  @observable malignantFinding = {
    ...this.initialMalignantFinding,
  };
  @observable liverMetastatectomy = [...this.initialLiverMetastatectomy];
  @observable seedingSite = [...this.initialSeedingSite];
  @observable resection = [...this.initialResection];
  @observable colonicResection = { ...this.initialColonicResection };
  @observable middleColicArtery = [...this.initialMiddleColicArtery];
  @observable rectalResection = { ...this.initialRectalResection };
  @observable rectalResectionIsInvalid = {
    ...this.initialRectalResectionIsInvalid,
  };
  @observable pelvicLymphNodeDissection = [
    ...this.initialPelvicLymphNodeDissection,
  ];
  @observable iliacVesselsResection = [...this.initialIliacVesselsResection];
  @observable ligation = { ...this.initialLigation };
  @observable ligationIsInvalid = { ...this.initialLigationIsInvalid };
  @observable detailOfVascularLigations = [
    ...this.initialDetailOfVascularLigations,
  ];
  @observable rectalTransactions = [...this.initialRectalTransactions];
  @observable isr = [...this.initialIsr];
  @observable levelOfSphincterExcision = [
    ...this.initialLevelOfSphincterExcision,
  ];
  @observable margin = { ...this.initialMargin };
  @observable organResection = { ...this.initialOrganResection };
  @observable organResectionIsInvalid = {
    ...this.initialOrganResectionIsInvalid,
  };
  @observable enBlocResectionOfAdjacentOrgans = [
    ...this.initialEnBlocResectionOfAdjacentOrgans,
  ];
  @observable diaphragms = [...this.initialDiaphragms];
  @observable stomachs = [...this.initialStomachs];
  @observable duodenums = [...this.initialDuodenums];
  @observable smallIntestines = [...this.initialSmallIntestines];
  @observable pancreases = [...this.initialPancreases];
  @observable kidney = [...this.initialKidney];
  @observable adrenalGlands = [...this.initialAdrenalGlands];
  @observable ureters = [...this.initialUreters];
  @observable urinaryBladders = [...this.initialUrinaryBladders];
  @observable uteruses = [...this.initialUteruses];
  @observable ovaries = [...this.initialOvaries];
  @observable vaginas = [...this.initialVaginas];
  @observable prostates = [...this.initialProstates];
  @observable pelvicFloors = [...this.initialPelvicFloors];
  @observable organResectionSacrectomy = [
    ...this.initialOrganResectionSacrectomy,
  ];
  @observable urinaryBladderReconstructionDetails = [
    ...this.initialUrinaryBladderReconstructionDetails,
  ];
  @observable anastomosisSection = { ...this.initialAnastomosisSection };
  @observable anastomoticTypes = [...this.initialAnastomoticTypes];
  @observable anastomoticTechniques = [...this.initialAnastomoticTechniques];
  @observable anastomosis = [...this.initialAnastomosis];
  @observable distalRectalWashouts = [...this.initialDistalRectalWashouts];
  @observable airLeakTests = [...this.initialAirLeakTests];
  @observable ostomy = { ...this.initialOstomy };
  @observable ostomySites = [...this.initialOstomySites];
  @observable ostomyTypes = [...this.initialOstomyTypes];
  @observable ostomyOpenings = [...this.initialOstomyOpenings];
  @observable ostomyMaturations = [...this.initialOstomyMaturations];
  @observable woundAndDrainage = { ...this.initialWoundAndDrainage };
  @observable bleedingCheck = { ...this.initialBleedingCheck };
  @observable surgicalWoundClassifications = [
    ...this.initialSurgicalWoundClassifications,
  ];
  @observable intraOperativeComplication = {
    ...this.initialIntraOperativeComplication,
  };

  constructor() {
    makeAutoObservable(this);
  }

  @action
  onSetIsReadOnly(value: boolean) {
    this.isReadOnly = value;
  }

  //   Action operative note information
  @action
  onSetOperativeNote(value: OperativeNoteModel) {
    this.operativeNote = { ...value };
  }
  @action
  onSetOperativeNoteIsInvalid(value: OperativeNoteIsInvalidModel) {
    this.operativeNoteIsInvalid = { ...value };
  }

  //   Action diagnosis
  @action
  onSetDiagnosisLocations(value: any) {
    this.diagnosisLocations = [...value];
  }

  //   Action synchronous lesion
  @action
  onSetSynchronousLesion(value: any) {
    this.synchronousLesions = [...value];
  }

  //   Action surgeons
  @action
  onSetSurgeons(value: SurgeonModel) {
    this.surgeons = { ...value };
  }
  //   Action assistant
  @action
  onSetAssistant(value: AssistantModel) {
    this.assistants = { ...value };
  }
  @action
  onSetAssistantIsInvalid(value: AssistantIsInvalidModel) {
    this.assistantIsInvalid = { ...value };
  }
  //   Action anesthesia
  @action
  onSetAnesthesia(value: any) {
    this.anesthesia = [...value];
  }

  // Action transfusion
  @action
  onSetTransfusion(value: any) {
    this.transfusion = { ...value };
  }

  // Action operation
  @action
  onSetOperativeProcedure(value: any) {
    this.operativeProcedures = [...value];
  }
  @action
  onSetOperation(value: any) {
    this.operation = { ...value };
  }
  @action
  onSetOperationIsInvalid(value: any) {
    this.operationIsInvalid = { ...value };
  }
  @action
  onSetPelvicExenteration(value: any) {
    this.pelvicExenteration = [...value];
  }
  @action
  onSetSacrectomy(value: any) {
    this.sacrectomy = [...value];
  }
  @action
  onSetSacrectomyExtended(value: any) {
    this.sacrectomyExtended = [...value];
  }
  @action
  onSetPosition(value: any) {
    this.position = [...value];
  }
  @action
  onSetTechnique(value: any) {
    this.technique = [...value];
  }
  @action
  onSetConversionToOpen(value: any) {
    this.conversionToOpen = [...value];
  }

  // Action malignancy
  @action
  onSetMalignancyCase(value: any) {
    this.malignancyCase = { ...value };
  }
  @action
  onSetMalignancyIsInvalid(value: MalignancyCaseIsInvalidModel) {
    this.malignancyCaseIsInvalid = { ...value };
  }
  @action
  onSetMalignancyCaseTreatmentIntention(value: any) {
    this.malignancyCaseTreatmentIntention = [...value];
  }
  @action
  onSetSeedingSite(value: any) {
    this.seedingSite = [...value];
  }

  // Action malignant finding
  @action
  onSetMalignantFinding(value: any) {
    this.malignantFinding = { ...value };
  }
  @action
  onSetLiverMetastatectomy(value: any) {
    this.liverMetastatectomy = [...value];
  }

  // Action resection
  @action
  onSetResection(value: any) {
    this.resection = [...value];
  }
  @action
  onSetColonicResection(value: any) {
    this.colonicResection = { ...value };
  }
  @action
  onSetMiddleColicArtery(value: any) {
    this.middleColicArtery = [...value];
  }
  @action
  onSetRectalResection(value: any) {
    this.rectalResection = { ...value };
  }
  @action
  onSetRectalResectionIsInvalid(value: any) {
    this.rectalResectionIsInvalid = { ...value };
  }
  @action
  onSetPelvicLymphNodeDissection(value: any) {
    this.pelvicLymphNodeDissection = [...value];
  }
  @action
  onSetIliacVesselsResection(value: any) {
    this.iliacVesselsResection = [...value];
  }

  // Action ligation
  @action
  onSetLigation(value: LigationModel) {
    this.ligation = { ...value };
  }
  @action
  onSetLigationIsInvalid(value: LigationIsInvalidModel) {
    this.ligationIsInvalid = { ...value };
  }
  @action
  onSetDetailOfVascularLigations(value: any) {
    this.detailOfVascularLigations = [...value];
  }
  @action
  onSetRectalTransactions(value: any) {
    this.rectalTransactions = [...value];
  }
  @action
  onSetIsr(value: any) {
    this.isr = [...value];
  }
  @action
  onSetLevelOfSphincterExcision(value: any) {
    this.levelOfSphincterExcision = [...value];
  }

  // Action margin
  @action
  onSetMargin(value: any) {
    this.margin = { ...value };
  }

  // Action organ resection
  @action
  onSetOrganResection(value: any) {
    this.organResection = { ...value };
  }
  @action
  onSetOrganResectionIsInvalid(value: any) {
    this.organResectionIsInvalid = { ...value };
  }
  @action
  onSetEnBlocResectionOfAdjacentOrgans(value: any) {
    this.enBlocResectionOfAdjacentOrgans = [...value];
  }
  @action
  onSetDiaphragms(value: any) {
    this.diaphragms = [...value];
  }
  @action
  onSetStomachs(value: any) {
    this.stomachs = [...value];
  }
  @action
  onSetDuodenums(value: any) {
    this.duodenums = [...value];
  }
  @action
  onSetSmallIntestines(value: any) {
    this.smallIntestines = [...value];
  }
  @action
  onSetPancreases(value: any) {
    this.pancreases = [...value];
  }
  @action
  onSetKidney(value: any) {
    this.kidney = [...value];
  }
  @action
  onSetAdrenalGlands(value: any) {
    this.adrenalGlands = [...value];
  }
  @action
  onSetUreters(value: any) {
    this.ureters = [...value];
  }
  @action
  onSetUrinaryBladders(value: any) {
    this.urinaryBladders = [...value];
  }
  @action
  onSetUteruses(value: any) {
    this.uteruses = [...value];
  }
  @action
  onSetOvaries(value: any) {
    this.ovaries = [...value];
  }
  @action
  onSetVaginas(value: any) {
    this.vaginas = [...value];
  }
  @action
  onSetProstates(value: any) {
    this.prostates = [...value];
  }
  @action
  onSetPelvicFloors(value: any) {
    this.pelvicFloors = [...value];
  }
  @action
  onSetOrganResectionSacrectomy(value: any) {
    this.organResectionSacrectomy = [...value];
  }
  @action
  onSetUrinaryBladderReconstructionDetails(value: any) {
    this.urinaryBladderReconstructionDetails = [...value];
  }

  // Action anastomosis section
  onSetAnastomosisSection(value: AnastomosisSectionModel) {
    this.anastomosisSection = { ...value };
  }
  onSetAnastomoticTypes(value: any) {
    this.anastomoticTypes = [...value];
  }
  onSetAnastomoticTechniques(value: any) {
    this.anastomoticTechniques = [...value];
  }
  onSetAnastomosis(value: any) {
    this.anastomosis = [...value];
  }
  onSetDistalRectalWashouts(value: any) {
    this.distalRectalWashouts = [...value];
  }
  onSetAirLeakTests(value: any) {
    this.airLeakTests = [...value];
  }

  // Action ostomy
  onSetOstomy(value: OstomyModel) {
    this.ostomy = { ...value };
  }
  onSetOstomySites(value: any) {
    this.ostomySites = [...value];
  }
  onSetOstomyTypes(value: any) {
    this.ostomyTypes = [...value];
  }
  onSetOstomyOpenings(value: any) {
    this.ostomyOpenings = [...value];
  }
  @action
  onSetOstomyMaturations(value: any) {
    this.ostomyMaturations = [...value];
  }

  // Action wound and drainage
  onSetWoundAndDrainage(value: WoundAndDrainageModel) {
    this.woundAndDrainage = { ...value };
  }

  // Action bleeding check
  onSetBleedingCheck(value: BleedingCheckModel) {
    this.bleedingCheck = { ...value };
  }
  onSetSurgicalWoundClassifications(value: any) {
    this.surgicalWoundClassifications = [...value];
  }

  // Action intra operation complication
  onSetIntraOperativeComplication(value: IntraOperativeComplicationModel) {
    this.intraOperativeComplication = { ...value };
  }

  // Clear
  @action
  onClearPelvicExenteration() {
    this.pelvicExenteration = [...this.initialPelvicExenteration];
  }
  @action
  onClearSacrectomy() {
    this.sacrectomy = [...this.initialSacrectomy];
    this.sacrectomyExtended = [...this.initialSacrectomyExtended];
  }
  @action
  onClearMalignancyCase() {
    this.malignancyCaseTreatmentIntention = [
      ...this.initialMalignancyCaseTreatmentIntention,
    ];
    this.malignantFinding = {
      ...this.initialMalignantFinding,
    };
    this.liverMetastatectomy = [...this.initialLiverMetastatectomy];
    this.seedingSite = [...this.initialSeedingSite];
  }
  @action
  onClearColonicResection() {
    this.colonicResection = { ...this.initialColonicResection };
    this.middleColicArtery = [...this.initialMiddleColicArtery];
  }
  @action
  onClearRectalResection() {
    this.rectalResection = { ...this.initialRectalResection };
    this.pelvicLymphNodeDissection = [...this.initialPelvicLymphNodeDissection];
    this.iliacVesselsResection = [...this.initialIliacVesselsResection];
    this.rectalTransactions = [...this.initialRectalTransactions];
    this.ligation.staplerPiece = 0;
    this.ligation.staplerValue = "";
    this.ligationIsInvalid.staplerPiece = false;
    this.ligationIsInvalid.staplerValue = false;
    this.onClearIsr();
  }
  @action
  onClearIsr() {
    this.isr = [...this.initialIsr];
    this.levelOfSphincterExcision = [...this.levelOfSphincterExcision];
    this.ligationIsInvalid.isr = false;
    this.ligationIsInvalid.levelOfSphincterExcision = false;
  }
  @action
  onClearDiaphragms() {
    this.diaphragms = [...this.initialDiaphragms];
  }
  @action
  onClearStomachs() {
    this.stomachs = [...this.initialStomachs];
  }
  @action
  onClearDuodenums() {
    this.duodenums = [...this.initialDuodenums];
  }
  @action
  onClearSmallIntestines() {
    this.smallIntestines = [...this.initialSmallIntestines];
  }
  @action
  onClearPancreases() {
    this.pancreases = [...this.initialPancreases];
  }
  @action
  onClearKidney() {
    this.kidney = [...this.initialKidney];
  }
  @action
  onClearAdrenalGlands() {
    this.adrenalGlands = [...this.initialAdrenalGlands];
  }
  @action
  onClearUreters() {
    this.ureters = [...this.initialUreters];
  }
  @action
  onClearUrinaryBladders() {
    this.urinaryBladders = [...this.initialUrinaryBladders];
  }
  @action
  onClearUteruses() {
    this.uteruses = [...this.initialUteruses];
  }
  @action
  onClearOvaries() {
    this.ovaries = [...this.initialOvaries];
  }
  @action
  onClearVaginas() {
    this.vaginas = [...this.initialVaginas];
  }
  @action
  onClearProstates() {
    this.prostates = [...this.initialProstates];
  }
  @action
  onClearPelvicFloors() {
    this.pelvicFloors = [...this.initialPelvicFloors];
  }
  @action
  onClearOrganResectionSacrectomy() {
    this.organResectionSacrectomy = [...this.initialOrganResectionSacrectomy];
  }
  @action
  onClearStore() {
    this.isReadOnly = false;
    this.operativeNote = {
      ...this.initialOperativeNote,
    };
    this.operativeNoteIsInvalid = {
      ...this.initialOperativeNoteIsInvalid,
    };
    this.diagnosisLocations = [...this.initialDiagnosisLocations];
    this.synchronousLesions = [...this.initialSynchronousLesions];
    this.surgeons = { ...this.initialSurgeons };
    this.assistants = { ...this.initialAssistants };
    this.assistantIsInvalid = {
      ...this.initialAssistantIsInvalid,
    };
    this.anesthesia = [...this.initialAnesthesia];
    this.transfusion = { ...this.initialTransfusion };
    this.operativeProcedures = [...this.initialOperativeProcedures];
    this.operation = { ...this.initialOperation };
    this.operationIsInvalid = { ...this.initialOperationIsInvalid };
    this.pelvicExenteration = [...this.initialPelvicExenteration];
    this.sacrectomy = [...this.initialSacrectomy];
    this.sacrectomyExtended = [...this.initialSacrectomyExtended];
    this.position = [...this.initialPosition];
    this.technique = [...this.initialTechnique];
    this.conversionToOpen = [...this.initialConversionToOpen];
    this.malignancyCase = {
      ...this.initialMalignancyCase,
    };
    this.malignancyCaseIsInvalid = {
      ...this.initialMalignancyCaseIsInvalid,
    };
    this.malignancyCaseTreatmentIntention = [
      ...this.initialMalignancyCaseTreatmentIntention,
    ];
    this.malignantFinding = {
      ...this.initialMalignantFinding,
    };
    this.liverMetastatectomy = [...this.initialLiverMetastatectomy];
    this.seedingSite = [...this.initialSeedingSite];
    this.resection = [...this.initialResection];
    this.colonicResection = { ...this.initialColonicResection };
    this.middleColicArtery = [...this.initialMiddleColicArtery];
    this.rectalResection = { ...this.initialRectalResection };
    this.rectalResectionIsInvalid = { ...this.initialRectalResectionIsInvalid };
    this.pelvicLymphNodeDissection = [...this.initialPelvicLymphNodeDissection];
    this.iliacVesselsResection = [...this.initialIliacVesselsResection];
    this.ligation = { ...this.initialLigation };
    this.ligationIsInvalid = { ...this.initialLigationIsInvalid };
    this.detailOfVascularLigations = [...this.initialDetailOfVascularLigations];
    this.rectalTransactions = [...this.initialRectalTransactions];
    this.isr = [...this.initialIsr];
    this.levelOfSphincterExcision = [...this.initialLevelOfSphincterExcision];
    this.margin = { ...this.initialMargin };
    this.organResection = { ...this.initialOrganResection };
    this.organResectionIsInvalid = {
      ...this.initialOrganResectionIsInvalid,
    };
    this.enBlocResectionOfAdjacentOrgans = [
      ...this.initialEnBlocResectionOfAdjacentOrgans,
    ];
    this.diaphragms = [...this.initialDiaphragms];
    this.stomachs = [...this.initialStomachs];
    this.duodenums = [...this.initialDuodenums];
    this.smallIntestines = [...this.initialSmallIntestines];
    this.pancreases = [...this.initialPancreases];
    this.kidney = [...this.initialKidney];
    this.adrenalGlands = [...this.initialAdrenalGlands];
    this.ureters = [...this.initialUreters];
    this.urinaryBladders = [...this.initialUrinaryBladders];
    this.uteruses = [...this.initialUteruses];
    this.ovaries = [...this.initialOvaries];
    this.vaginas = [...this.initialVaginas];
    this.prostates = [...this.initialProstates];
    this.pelvicFloors = [...this.initialPelvicFloors];
    this.organResectionSacrectomy = [...this.initialOrganResectionSacrectomy];
    this.urinaryBladderReconstructionDetails = [
      ...this.initialUrinaryBladderReconstructionDetails,
    ];
    this.anastomosisSection = { ...this.initialAnastomosisSection };
    this.anastomoticTypes = [...this.initialAnastomoticTypes];
    this.anastomoticTechniques = [...this.initialAnastomoticTechniques];
    this.anastomosis = [...this.initialAnastomosis];
    this.distalRectalWashouts = [...this.initialDistalRectalWashouts];
    this.airLeakTests = [...this.initialAirLeakTests];
    this.ostomy = { ...this.initialOstomy };
    this.ostomySites = [...this.initialOstomySites];
    this.ostomyTypes = [...this.initialOstomyTypes];
    this.ostomyOpenings = [...this.initialOstomyOpenings];
    this.ostomyMaturations = [...this.initialOstomyMaturations];
    this.woundAndDrainage = { ...this.initialWoundAndDrainage };
    this.bleedingCheck = { ...this.initialBleedingCheck };
    this.surgicalWoundClassifications = [
      ...this.initialSurgicalWoundClassifications,
    ];
    this.intraOperativeComplication = {
      ...this.initialIntraOperativeComplication,
    };
  }

  // Validate form
  onValidateOperativeInformationForm() {
    this.operativeNoteIsInvalid.operativeSetting = Boolean(
      !this.operativeNote?.operativeSetting
    );
    this.operativeNoteIsInvalid.emergencyDetail = Boolean(
      this.operativeNote?.operativeSetting ===
        OperativeSettingEnumConstant.emergency &&
        !this.operativeNote?.emergencyDetail?.id
    );
    return Object.values(this.operativeNoteIsInvalid).every((v) => v === false);
  }
  onValidateAssistantForm() {
    const totalOperativeTime =
      (this.assistants?.operativeStartAt &&
        this.assistants?.operativeStopAt &&
        CalculateTotalDateTimeHourMinUtil(
          this.assistants?.operativeStartAt,
          this.assistants?.operativeStopAt
        )) ||
      "-";
    this.assistantIsInvalid.anesthesiologist = Boolean(
      !this.assistants?.anesthesiologist
    );
    this.assistantIsInvalid.operativeStartAt = Boolean(
      totalOperativeTime[0] === "-" || !this.assistants?.operativeStartAt
    );
    this.assistantIsInvalid.operativeStopAt = Boolean(
      totalOperativeTime[0] === "-" || !this.assistants?.operativeStopAt
    );
    return Object.values(this.assistantIsInvalid).every((v) => v === false);
  }
  onValidateOperationForm() {
    const filterOperativeProcedureSelected = this.operativeProcedures.filter(
      (item) => item.value
    );
    const findAbdominoperinealResectionValue =
      filterOperativeProcedureSelected.find(
        (item) =>
          item.key === OperativeProcedureKeyConstant.abdominoperinealResection
      );
    const findPelvicExenteration = filterOperativeProcedureSelected.find(
      (item) => item.key === OperativeProcedureKeyConstant.pelvicExenteration
    );
    const findSacretomy = filterOperativeProcedureSelected.find(
      (item) => item.key === OperativeProcedureKeyConstant.sacrectomy
    );
    const findOtherProcedureDetail = filterOperativeProcedureSelected.find(
      (item) => item.key === OperativeProcedureKeyConstant.otherProcedure
    );
    if (findAbdominoperinealResectionValue)
      this.operationIsInvalid.abdominoperinealResectionValue = Boolean(
        !this.operation?.abdominoperinealResectionValue?.id
      );
    if (findPelvicExenteration) {
      const filterPelvicExenterationSelected = this.pelvicExenteration?.filter(
        (item) => item.value
      );
      this.operationIsInvalid.pelvicExenteration = Boolean(
        !filterPelvicExenterationSelected?.length
      );
    }
    if (findSacretomy) {
      const filterSacretomySubSelected = this.sacrectomy?.filter(
        (item) => item.value
      );
      const filterSacretomyExtendedSelected = this.sacrectomyExtended?.filter(
        (item) => item.value
      );
      this.operationIsInvalid.sacrectomy = Boolean(
        !filterSacretomySubSelected.length
      );
      this.operationIsInvalid.sacrectomyExtended = Boolean(
        !filterSacretomyExtendedSelected.length
      );
    }
    if (findOtherProcedureDetail) {
      this.operationIsInvalid.otherProcedureDetail = Boolean(
        !this.operation?.otherProcedureDetail
      );
    }
    return Object.values(this.operationIsInvalid).every((v) => v === false);
  }
  onValidateMalignancyCaseForm() {
    const filterTreatmentIntentionSelected =
      this.malignancyCaseTreatmentIntention?.filter((item) => item.value);
    this.malignancyCaseIsInvalid.treatmentIntention = Boolean(
      this.malignancyCase?.malignancyCase &&
        this.malignancyCase?.malignancyCase === "true" &&
        !filterTreatmentIntentionSelected.length
    );
    this.malignancyCaseIsInvalid.malignancyCase = Boolean(
      !this.malignancyCase.malignancyCase
    );
    return Object.values(this.malignancyCaseIsInvalid).every(
      (v) => v === false
    );
  }
  onValidateLigationForm() {
    const filterRectalTransactionSelected = this.rectalTransactions?.filter(
      (item) => item.value
    );
    const findStapletSelected = filterRectalTransactionSelected.find(
      (item) => item.key === RectalTransactionKeyConstant.stapler
    );
    const fildIsrSlected = filterRectalTransactionSelected.find(
      (item) => item.key === RectalTransactionKey.isr
    );
    if (findStapletSelected) {
      this.ligationIsInvalid.staplerValue = Boolean(
        !this.ligation?.staplerValue
      );
      this.ligationIsInvalid.staplerPiece = Boolean(
        !String(this.ligation?.staplerPiece)
      );
    }
    if (fildIsrSlected) {
      const filterIsrSelected = this.isr?.filter((item) => item.value);
      const filterLevelOfSphincterExcisionSelected =
        this.levelOfSphincterExcision?.filter((item) => item.value);
      this.ligationIsInvalid.isr = Boolean(!filterIsrSelected?.length);
      this.ligationIsInvalid.levelOfSphincterExcision = Boolean(
        !filterLevelOfSphincterExcisionSelected?.length
      );
    }
    return Object.values(this.ligationIsInvalid).every((v) => v === false);
  }
  onValidateOrganResectionForm() {
    const filterEnBlocResectionOfAdjacentOrgansSelected =
      this.enBlocResectionOfAdjacentOrgans?.filter((item) => item.value);
    const findDiaphragmSelected =
      filterEnBlocResectionOfAdjacentOrgansSelected?.find(
        (item) =>
          item.key === EnBlocResectionOfAdjacentOrgansKeyConstant.diaphragm
      );
    const findStomachSelected =
      filterEnBlocResectionOfAdjacentOrgansSelected?.find(
        (item) =>
          item.key === EnBlocResectionOfAdjacentOrgansKeyConstant.stomach
      );
    const findDuodenumSelected =
      filterEnBlocResectionOfAdjacentOrgansSelected?.find(
        (item) =>
          item.key === EnBlocResectionOfAdjacentOrgansKeyConstant.duodenum
      );
    const findSmallIntestineSelected =
      filterEnBlocResectionOfAdjacentOrgansSelected?.find(
        (item) =>
          item.key === EnBlocResectionOfAdjacentOrgansKeyConstant.smallIntestine
      );
    const findPancreasSelected =
      filterEnBlocResectionOfAdjacentOrgansSelected?.find(
        (item) => item.key === EnBlocResectionOfAdjacentOrgansKey?.pancreas
      );
    const findKidneySelected =
      filterEnBlocResectionOfAdjacentOrgansSelected?.find(
        (item) => item.key === EnBlocResectionOfAdjacentOrgansKey?.kidney
      );
    const findAdrenalGlandSelected =
      filterEnBlocResectionOfAdjacentOrgansSelected?.find(
        (item) => item.key === EnBlocResectionOfAdjacentOrgansKey?.adrenalGland
      );
    const findUreterSelected =
      filterEnBlocResectionOfAdjacentOrgansSelected?.find(
        (item) => item.key === EnBlocResectionOfAdjacentOrgansKey?.ureter
      );
    const findUrinaryBladderSelected =
      filterEnBlocResectionOfAdjacentOrgansSelected?.find(
        (item) => item.key === EnBlocResectionOfAdjacentOrgansKey.urinaryBladder
      );
    const findUterusSelected =
      filterEnBlocResectionOfAdjacentOrgansSelected?.find(
        (item) => item.key === EnBlocResectionOfAdjacentOrgansKey?.uterus
      );
    const findOvarySelected =
      filterEnBlocResectionOfAdjacentOrgansSelected?.find(
        (item) => item.key === EnBlocResectionOfAdjacentOrgansKey?.ovary
      );
    const findVaginaSelected =
      filterEnBlocResectionOfAdjacentOrgansSelected?.find(
        (item) => item.key === EnBlocResectionOfAdjacentOrgansKey?.vagina
      );
    const findProstateSelected =
      filterEnBlocResectionOfAdjacentOrgansSelected?.find(
        (item) => item.key === EnBlocResectionOfAdjacentOrgansKey?.prostate
      );
    const findPelvicFloorSelected =
      filterEnBlocResectionOfAdjacentOrgansSelected?.find(
        (item) => item.key === EnBlocResectionOfAdjacentOrgansKey?.pelvicFloor
      );
    const findSacretomySelected =
      filterEnBlocResectionOfAdjacentOrgansSelected?.find(
        (item) => item.key === EnBlocResectionOfAdjacentOrgansKey?.sacrectomy
      );
    const findOtherOrganSelected =
      filterEnBlocResectionOfAdjacentOrgansSelected?.find(
        (item) => item.key === EnBlocResectionOfAdjacentOrgansKey?.otherOrgan
      );
    if (findDiaphragmSelected) {
      const filterDiaphragmSelected = this.diaphragms?.filter(
        (item) => item.value
      );
      this.organResectionIsInvalid.diaphragm = Boolean(
        !filterDiaphragmSelected?.length
      );
    }
    if (findStomachSelected) {
      const filterStomachSelected = this.stomachs?.filter((item) => item.value);
      this.organResectionIsInvalid.stomach = Boolean(
        !filterStomachSelected?.length
      );
    }
    if (findDuodenumSelected) {
      const filterDuodenumSelected = this.duodenums?.filter(
        (item) => item.value
      );
      this.organResectionIsInvalid.duodenum = Boolean(
        !filterDuodenumSelected?.length
      );
    }
    if (findSmallIntestineSelected) {
      const filterSmallIntestineSelected = this.smallIntestines?.filter(
        (item) => item.value
      );
      this.organResectionIsInvalid.smallIntestine = Boolean(
        !filterSmallIntestineSelected?.length
      );
    }
    if (findPancreasSelected) {
      const filterPancreasSelected = this.pancreases?.filter(
        (item) => item.value
      );
      this.organResectionIsInvalid.pancreas = Boolean(
        !filterPancreasSelected?.length
      );
    }
    if (findKidneySelected) {
      const filterKidneySelected = this.kidney?.filter((item) => item.value);
      this.organResectionIsInvalid.kidney = Boolean(
        !filterKidneySelected?.length
      );
    }
    if (findAdrenalGlandSelected) {
      const filterAdrenalGlandSelected = this.adrenalGlands?.filter(
        (item) => item.value
      );
      this.organResectionIsInvalid.adrenalGland = Boolean(
        !filterAdrenalGlandSelected?.length
      );
    }
    if (findUreterSelected) {
      const filterUreterSelected = this.ureters?.filter((item) => item.value);
      this.organResectionIsInvalid.ureter = Boolean(
        !filterUreterSelected?.length
      );
    }
    if (findUrinaryBladderSelected) {
      const filterUrinaryBladderSelected = this.urinaryBladders?.filter(
        (item) => item.value
      );
      this.organResectionIsInvalid.urinaryBladder = Boolean(
        !filterUrinaryBladderSelected?.length
      );
    }
    if (findUterusSelected) {
      const filterUterusSelected = this.uteruses?.filter((item) => item.value);
      this.organResectionIsInvalid.uterus = Boolean(
        !filterUterusSelected?.length
      );
    }
    if (findOvarySelected) {
      const filterOvarySelected = this.ovaries?.filter((item) => item.value);
      this.organResectionIsInvalid.ovary = Boolean(
        !filterOvarySelected?.length
      );
    }
    if (findVaginaSelected) {
      const filterVaginaSelected = this.vaginas?.filter((item) => item.value);
      this.organResectionIsInvalid.vagina = Boolean(
        !filterVaginaSelected.length
      );
    }
    if (findProstateSelected) {
      const filterProstateSelected = this.prostates?.filter(
        (item) => item.value
      );
      this.organResectionIsInvalid.prostate = Boolean(
        !filterProstateSelected?.length
      );
    }
    if (findPelvicFloorSelected) {
      const filterPelvicFloorSelected = this.pelvicFloors?.filter(
        (item) => item.value
      );
      this.organResectionIsInvalid.pelvicFloor = Boolean(
        !filterPelvicFloorSelected?.length
      );
    }
    if (findSacretomySelected) {
      const filterSacretomySelected = this.organResectionSacrectomy?.filter(
        (item) => item.value
      );
      this.organResectionIsInvalid.sacrectomy = Boolean(
        !filterSacretomySelected?.length
      );
    }
    if (findOtherOrganSelected) {
      this.organResectionIsInvalid.otherOrganDetail = Boolean(
        !this.organResection?.otherOrganDetail
      );
    }
    return Object.values(this.organResectionIsInvalid).every(
      (v) => v === false
    );
  }
  onValidateRectalResectionForm() {
    this.rectalResectionIsInvalid.splenicFlexureMobilization = Boolean(
      !this.rectalResection.splenicFlexureMobilization
    );
    return Object.values(this.rectalResectionIsInvalid).every(
      (v) => v === false
    );
  }
  @action
  onValidateForm() {
    let formIsValid = true;
    const formOperativeInformationIsValid =
      this.onValidateOperativeInformationForm();
    const formAssistantIsValid = this.onValidateAssistantForm();
    const formOperationIsValid = this.onValidateOperationForm();
    const formMalignancyIsValid = this.onValidateMalignancyCaseForm();
    const formRectalResection = this.onValidateRectalResectionForm();
    const formLigationIsValid = this.onValidateLigationForm();
    const formOrganResectionIsValid = this.onValidateOrganResectionForm();
    if (!formOperativeInformationIsValid)
      ScrollToElementIdUtil(
        ColorectalSurgeryOperativeNoteFormConstant.operativeInformation
      );
    else if (!formAssistantIsValid)
      ScrollToElementIdUtil(
        ColorectalSurgeryOperativeNoteFormConstant.assistant
      );
    else if (!formOperationIsValid)
      ScrollToElementIdUtil(
        ColorectalSurgeryOperativeNoteFormConstant.operation
      );
    else if (!formMalignancyIsValid)
      ScrollToElementIdUtil(
        ColorectalSurgeryOperativeNoteFormConstant.malignancyCase
      );
    else if (!formRectalResection)
      ScrollToElementIdUtil(
        ColorectalSurgeryOperativeNoteFormConstant.resection
      );
    else if (!formLigationIsValid)
      ScrollToElementIdUtil(
        ColorectalSurgeryOperativeNoteFormConstant.ligation
      );
    else if (!formOrganResectionIsValid)
      ScrollToElementIdUtil(
        ColorectalSurgeryOperativeNoteFormConstant.organsResection
      );
    formIsValid = Boolean(
      formOperativeInformationIsValid &&
        formAssistantIsValid &&
        formOperationIsValid &&
        formMalignancyIsValid &&
        formRectalResection &&
        formLigationIsValid &&
        formOrganResectionIsValid
    );
    return formIsValid;
  }

  @action
  onGetOperativeNoteBody() {
    const objectDiagnosis = Object.fromEntries(
      this.diagnosisLocations?.map((obj) => [obj.key, obj.value])
    );
    const objectSynchronousLesion = Object.fromEntries(
      this.synchronousLesions?.map((obj) => [obj.key, obj.value])
    );
    const objectAnesthesia = Object.fromEntries(
      this.anesthesia?.map((obj) => [obj.key, obj.value])
    );
    const objectOperativeProcedure = Object.fromEntries(
      this.operativeProcedures?.map((obj) => [obj.key, obj.value])
    );
    const objectPelvicExenteration = Object.fromEntries(
      this.pelvicExenteration?.map((obj) => [obj.key, obj.value])
    );
    const objectSacrectomy = Object.fromEntries(
      this.sacrectomy?.map((obj) => [obj.key, obj.value])
    );
    const objectSacrectomyExtended = Object.fromEntries(
      this.sacrectomyExtended?.map((obj) => [obj.key, obj.value])
    );
    const objectPosition = Object.fromEntries(
      this.position?.map((obj) => [obj.key, obj.value])
    );
    const objectTechnique = Object.fromEntries(
      this.technique?.map((obj) => [obj.key, obj.value])
    );
    const objectMalignancyCaseTreatmentIntention = Object.fromEntries(
      this.malignancyCaseTreatmentIntention?.map((obj) => [obj.key, obj.value])
    );
    const objectLiverMetastatectomy = Object.fromEntries(
      this.liverMetastatectomy?.map((obj) => [obj.key, obj.value])
    );
    const objectSeedingSite = Object.fromEntries(
      this.seedingSite?.map((obj) => [obj.key, obj.value])
    );
    const objectResection = Object.fromEntries(
      this.resection?.map((obj) => [obj.key, obj.value])
    );
    const objectMiddleColicArtery = Object.fromEntries(
      this.middleColicArtery?.map((obj) => [obj.key, obj.value])
    );
    const objectPelvicLymphNodeDissection = Object.fromEntries(
      this.pelvicLymphNodeDissection?.map((obj) => [obj.key, obj.value])
    );
    const objectIliacVesselsResection = Object.fromEntries(
      this.iliacVesselsResection?.map((obj) => [obj.key, obj.value])
    );
    const objectDetailOfVascularLigation = Object.fromEntries(
      this.detailOfVascularLigations?.map((obj) => [obj.key, obj.value])
    );
    const objectRectalTransaction = Object.fromEntries(
      this.rectalTransactions?.map((obj) => [obj.key, obj.value])
    );
    const objectIsr = Object.fromEntries(
      this.isr?.map((obj) => [obj.key, obj.value])
    );
    const objectLevelOfSphincterExcision = Object.fromEntries(
      this.levelOfSphincterExcision?.map((obj) => [obj.key, obj.value])
    );
    const objectEnBlocResectionOfAdjacentOrgans = Object.fromEntries(
      this.enBlocResectionOfAdjacentOrgans?.map((obj) => [obj.key, obj.value])
    );
    const objectDiaphragm = Object.fromEntries(
      this.diaphragms?.map((obj) => [obj.key, obj.value])
    );
    const objectStomach = Object.fromEntries(
      this.stomachs?.map((obj) => [obj.key, obj.value])
    );
    const objectDuodenum = Object.fromEntries(
      this.duodenums?.map((obj) => [obj.key, obj.value])
    );
    const objectSmallIntestine = Object.fromEntries(
      this.smallIntestines?.map((obj) => [obj.key, obj.value])
    );
    const objectPancreas = Object.fromEntries(
      this.pancreases?.map((obj) => [obj.key, obj.value])
    );
    const objectKidney = Object.fromEntries(
      this.kidney?.map((obj) => [obj.key, obj.value])
    );
    const objectAdrenalGland = Object.fromEntries(
      this.adrenalGlands?.map((obj) => [obj.key, obj.value])
    );
    const objectUreter = Object.fromEntries(
      this.ureters?.map((obj) => [obj.key, obj.value])
    );
    const objectUrinaryBladder = Object.fromEntries(
      this.urinaryBladders?.map((obj) => [obj.key, obj.value])
    );
    const objectUterus = Object.fromEntries(
      this.uteruses?.map((obj) => [obj.key, obj.value])
    );
    const objectOvary = Object.fromEntries(
      this.ovaries?.map((obj) => [obj.key, obj.value])
    );
    const objectVagina = Object.fromEntries(
      this.vaginas?.map((obj) => [obj.key, obj.value])
    );
    const objectProstate = Object.fromEntries(
      this.prostates?.map((obj) => [obj.key, obj.value])
    );
    const objectPelvicFloor = Object.fromEntries(
      this.pelvicFloors?.map((obj) => [obj.key, obj.value])
    );
    const objectOrganResectionSacrectomy = Object.fromEntries(
      this.organResectionSacrectomy?.map((obj) => [obj.key, obj.value])
    );
    const objectUrinaryBladderReconstructionDetail = Object.fromEntries(
      this.urinaryBladderReconstructionDetails?.map((obj) => [
        obj.key,
        obj.value,
      ])
    );
    const objectAnastomoticType = Object.fromEntries(
      this.anastomoticTypes?.map((obj) => [obj.key, obj.value])
    );
    const objectAnastomoticTechnique = Object.fromEntries(
      this.anastomoticTechniques?.map((obj) => [obj.key, obj.value])
    );
    const objectAnastomosis = Object.fromEntries(
      this.anastomosis?.map((obj) => [obj.key, obj.value])
    );
    const objectDistalRectalWashout = Object.fromEntries(
      this.distalRectalWashouts?.map((obj) => [obj.key, obj.value])
    );
    const objectAirLeakTest = Object.fromEntries(
      this.airLeakTests?.map((obj) => [obj.key, obj.value])
    );
    const objectOstomySite = Object.fromEntries(
      this.ostomySites?.map((obj) => [obj.key, obj.value])
    );
    const objectOstomyType = Object.fromEntries(
      this.ostomyTypes?.map((obj) => [obj.key, obj.value])
    );
    const objectOstomyOpening = Object.fromEntries(
      this.ostomyOpenings?.map((obj) => [obj.key, obj.value])
    );
    const objectOstomyMaturation = Object.fromEntries(
      this.ostomyMaturations?.map((obj) => [obj.key, obj.value])
    );
    const objectSurgicalWoundClassification = Object.fromEntries(
      this.surgicalWoundClassifications?.map((obj) => [obj.key, obj.value])
    );
    const {
      surgeonOne,
      surgeonOneType,
      surgeonTwo,
      surgeonTwoType,
      surgeonThree,
      surgeonThreeType,
    } = this.surgeons;
    const {
      assistantOne,
      assistantOneType,
      assistantTwo,
      assistantTwoType,
      assistantThree,
      assistantThreeType,
    } = this.assistants;
    const { prc, ffp, platelet } = this.transfusion;
    const files =
      this.operativeNote?.files?.map((file) => {
        return file.id;
      }) || [];
    const body = {
      operativeInformation: {
        operativeDate:
          (this.operativeNote?.operativeDate &&
            `${dayjs(this.operativeNote?.operativeDate).format(
              "YYYY-MM-DD"
            )} 00:00:00`) ||
          "",
        ...(this.operativeNote?.operativeSetting && {
          operativeSetting: this.operativeNote?.operativeSetting,
        }),
        ...((this.operativeNote?.emergencyDetail?.id ||
          this.operativeNote?.emergencyDetail?.id === null) && {
          emergencyDetail: this.operativeNote?.emergencyDetail?.id,
        }),
      },
      diagnosis: {
        ...((this.operativeNote?.colorectalSurgery?.id ||
          this.operativeNote?.colorectalSurgery?.id === null) && {
          colorectalSurgery: this.operativeNote?.colorectalSurgery?.id,
        }),
        ...objectDiagnosis,
      },
      synchronousLesion: {
        ...objectSynchronousLesion,
        detail: this.operativeNote?.synchronousLesionDetail,
      },
      surgeon: {
        ...((surgeonOne?.id || surgeonOne?.id === null) && {
          surgeonOne: surgeonOne?.id,
        }),
        ...((surgeonOneType?.id || surgeonOneType?.id === null) && {
          surgeonOneType: surgeonOneType?.id,
        }),
        ...((surgeonTwo?.id || surgeonTwo?.id === null) && {
          surgeonTwo: surgeonTwo?.id,
        }),
        ...((surgeonTwoType?.id || surgeonTwoType?.id === null) && {
          surgeonTwoType: surgeonTwoType?.id,
        }),
        ...((surgeonThree?.id || surgeonThree?.id === null) && {
          surgeonThree: surgeonThree?.id,
        }),
        ...((surgeonThreeType?.id || surgeonThreeType?.id === null) && {
          surgeonThreeType: surgeonThreeType?.id,
        }),
        ...((assistantOne?.id || assistantOne?.id === null) && {
          assistantOne: assistantOne?.id,
        }),
        ...((assistantOneType?.id || assistantOneType?.id === null) && {
          assistantOneType: assistantOneType?.id,
        }),
        ...((assistantTwo?.id || assistantTwo?.id === null) && {
          assistantTwo: assistantTwo?.id,
        }),
        ...((assistantTwoType?.id || assistantTwoType?.id === null) && {
          assistantTwoType: assistantTwoType?.id,
        }),
        ...((assistantThree?.id || assistantThree?.id === null) && {
          assistantThree: assistantThree?.id,
        }),
        ...((assistantThreeType?.id || assistantThreeType?.id === null) && {
          assistantThreeType: assistantThreeType?.id,
        }),
        otherAssistant: this.assistants?.otherAssistant,
        ...objectAnesthesia,
        anesthesiologist: this.assistants?.anesthesiologist,
        scrubNurse: this.assistants?.scrubNurse,
        operativeStartAt:
          (this.assistants?.operativeStartAt &&
            `${dayjs(this.assistants?.operativeStartAt)
              .add(-7, "hour")
              .format("YYYY-MM-DD HH:mm:00")}`) ||
          "",
        operativeStopAt:
          (this.assistants?.operativeStopAt &&
            `${dayjs(this.assistants?.operativeStopAt)
              .add(-7, "hour")
              .format("YYYY-MM-DD HH:mm:00")}`) ||
          "",
        totalOperativeTime: this.assistants?.totalOperativeTime,
        bloodLoss: Number(this.assistants?.bloodLoss),
      },
      transfusion: {
        prc: (`${prc}` && Number(prc)) || 0,
        ffp: (`${ffp}` && Number(ffp)) || 0,
        platelet: (`${platelet}` && Number(platelet)) || 0,
      },
      operation: {
        ...objectOperativeProcedure,
        ...((this.operation?.abdominoperinealResectionValue?.id ||
          this.operation?.abdominoperinealResectionValue?.id === null) && {
          abdominoperinealResectionValue:
            this.operation?.abdominoperinealResectionValue?.id,
        }),
        ...objectPelvicExenteration,
        ...objectSacrectomy,
        ...objectSacrectomyExtended,
        otherProcedureDetail: this.operation?.otherProcedureDetail,
        ...objectPosition,
        ...objectTechnique,
        conversionToOpen: this.conversionToOpen[0]?.value || false,
      },
      malignancyCase: {
        ...(this.malignancyCase?.malignancyCase && {
          malignancyCase: this.malignancyCase?.malignancyCase === "true",
        }),
        ...objectMalignancyCaseTreatmentIntention,
      },
      malignantFinding: {
        ...(this.malignantFinding?.liverMetastasis && {
          liverMetastasis: this.malignantFinding?.liverMetastasis === "true",
        }),
        ...objectLiverMetastatectomy,
        ...(this.malignantFinding?.ascites && {
          ascites: this.malignantFinding?.ascites === "true",
        }),
        ...(`${this.malignantFinding?.ascitesAmount}` && {
          ascitesAmount: Number(this.malignantFinding?.ascitesAmount),
        }),
        ...(this.malignantFinding?.peritonealSeeding && {
          peritonealSeeding:
            this.malignantFinding?.peritonealSeeding === "true",
        }),
        ...objectSeedingSite,
        otherQuadrantsDetail: this.malignantFinding?.otherQuadrantsDetail,
      },
      resection: {
        ...objectResection,
        colonicResectionValue: {
          ...(this.colonicResection?.completeMesocolicExcision && {
            completeMesocolicExcision:
              this.colonicResection?.completeMesocolicExcision === "true",
          }),
          ...(this.colonicResection?.qualityOfCmeSpecimen && {
            qualityOfCmeSpecimen: this.colonicResection?.qualityOfCmeSpecimen,
          }),
          ...objectMiddleColicArtery,
        },
        rectalResectionValue: {
          ...((this.rectalResection?.totalMesorectalExcision?.id ||
            this.rectalResection?.totalMesorectalExcision?.id === null) && {
            totalMesorectalExcision:
              this.rectalResection?.totalMesorectalExcision?.id,
          }),
          ...(this.rectalResection?.splenicFlexureMobilization && {
            splenicFlexureMobilization:
              this.rectalResection?.splenicFlexureMobilization === "true",
          }),
          ...((this.rectalResection?.dissectionPlaneOfCircumferential?.id ||
            this.rectalResection?.dissectionPlaneOfCircumferential?.id ===
              null) && {
            dissectionPlaneOfCircumferential:
              this.rectalResection?.dissectionPlaneOfCircumferential?.id,
          }),
          ...(this.rectalResection?.qualityOfTmeSpecimen && {
            qualityOfTmeSpecimen: this.rectalResection?.qualityOfTmeSpecimen,
          }),
          ...objectPelvicLymphNodeDissection,
          ...objectIliacVesselsResection,
          ...((this.rectalResection?.pelvicAutonomicNerve?.id ||
            this.rectalResection?.pelvicAutonomicNerve?.id === null) && {
            pelvicAutonomicNerve:
              this.rectalResection?.pelvicAutonomicNerve?.id,
          }),
          pelvicAutonomicNerveDetail:
            this.rectalResection?.pelvicAutonomicNerveDetail,
        },
      },
      ligation: {
        ...(this.ligation?.centralVascularLigation && {
          centralVascularLigation:
            this.ligation?.centralVascularLigation === "true",
        }),
        ...objectDetailOfVascularLigation,
        ...((this.ligation?.imaLigation?.id ||
          this.ligation?.imaLigation?.id === null) && {
          imaLigation: this.ligation?.imaLigation?.id,
        }),
        ...((this.ligation?.imvLigation?.id ||
          this.ligation?.imvLigation?.id === null) && {
          imvLigation: this.ligation?.imvLigation?.id,
        }),
        ...objectRectalTransaction,
        ...(this.ligation?.staplerValue && {
          staplerValue: this.ligation?.staplerValue,
        }),
        ...(`${this.ligation?.staplerPiece}` && {
          staplerPiece: Number(this.ligation?.staplerPiece),
        }),
        ...objectIsr,
        ...objectLevelOfSphincterExcision,
      },
      margin: {
        distalMargin:
          (`${this.margin?.distalMargin}` &&
            Number(this.margin?.distalMargin)) ||
          0,
        proximalMargin:
          (`${this.margin?.proximalMargin}` &&
            Number(this.margin?.proximalMargin)) ||
          0,
        ...((this.margin?.grosslyMargin?.id ||
          this.margin?.grosslyMargin?.id === null) && {
          grosslyMargin: this.margin?.grosslyMargin?.id,
        }),
      },
      organsResection: {
        ...(this.organResection?.adjacentOrgansInvasion && {
          adjacentOrgansInvasion:
            this.organResection?.adjacentOrgansInvasion === "true",
        }),
        ...objectEnBlocResectionOfAdjacentOrgans,
        ...objectDiaphragm,
        ...objectStomach,
        ...objectDuodenum,
        ...objectSmallIntestine,
        ...objectPancreas,
        ...objectKidney,
        ...objectAdrenalGland,
        ...objectUreter,
        ...objectUrinaryBladder,
        ...objectUterus,
        ...objectOvary,
        ...objectVagina,
        ...objectProstate,
        ...objectPelvicFloor,
        ...objectOrganResectionSacrectomy,
        otherOrganDetail: this.organResection?.otherOrganDetail,
        ...objectUrinaryBladderReconstructionDetail,
        ...((this.organResection?.vaginalReconstruction?.id ||
          this.organResection?.vaginalReconstruction?.id === null) && {
          vaginalReconstruction: this.organResection?.vaginalReconstruction?.id,
        }),
        vaginalReconstructionDetail:
          this.organResection?.vaginalReconstructionDetail,
        ...((this.organResection?.palvicFloorDefectReconstruction?.id ||
          this.organResection?.palvicFloorDefectReconstruction?.id ===
            null) && {
          palvicFloorDefectReconstruction:
            this.organResection?.palvicFloorDefectReconstruction?.id,
        }),
        palvicFloorDefectReconstructionDetail:
          this.organResection?.palvicFloorDefectReconstructionDetail,
        ...((this.organResection?.closureOfPerinealWound?.id ||
          this.organResection?.closureOfPerinealWound?.id === null) && {
          closureOfPerinealWound:
            this.organResection?.closureOfPerinealWound?.id,
        }),
        closureOfPerinealWoundDetail:
          this.organResection?.closureOfPerinealWoundDetail,
      },
      anastomosisSection: {
        ...objectAnastomoticType,
        ...objectAnastomoticTechnique,
        ...(`${this.anastomosisSection?.linearStaple}` && {
          linearStaple: `${this.anastomosisSection?.linearStaple}`,
        }),
        ...((this.anastomosisSection?.circularStaple?.id ||
          this.anastomosisSection?.circularStaple?.id == null) && {
          circularStaple: this.anastomosisSection?.circularStaple?.id,
        }),
        ...objectAnastomosis,
        ...objectDistalRectalWashout,
        ...objectAirLeakTest,
        ...((this.anastomosisSection?.repairBy?.id ||
          this.anastomosisSection?.repairBy?.id === null) && {
          repairBy: this.anastomosisSection?.repairBy?.id,
        }),
        repairByDetail: this.anastomosisSection?.repairByDetail,
      },
      ostomy: {
        ostomy: this.ostomy?.ostomy,
        ostomyOption: this.ostomy?.option,
        ...objectOstomySite,
        ...objectOstomyType,
        ...objectOstomyOpening,
        ...objectOstomyMaturation,
      },
      woundAndDrainage: {
        ...(this.woundAndDrainage?.abdominalCavityDrainage && {
          abdominalCavityDrainage:
            this.woundAndDrainage?.abdominalCavityDrainage,
        }),
        abdominalCavityDrainageDetail:
          this.woundAndDrainage?.abdominalCavityDrainageDetail,
        ...(this.woundAndDrainage?.perinealWoundDrainage && {
          perinealWoundDrainage: this.woundAndDrainage?.perinealWoundDrainage,
        }),
        perinealWoundDrainageDetail:
          this.woundAndDrainage?.perinealWoundDrainageDetail,
      },
      bleedingCheck: {
        bleedingCheck: this.bleedingCheck?.bleedingCheck,
        stoppedAt: this.bleedingCheck?.stoppedAt,
        ...objectSurgicalWoundClassification,
        ...(this.bleedingCheck?.rectalTubeInsertion && {
          rectalTubeInsertion:
            this.bleedingCheck?.rectalTubeInsertion === "true",
        }),
        ...(this.bleedingCheck?.spillageOfBowelContent && {
          spillageOfBowelContent:
            this.bleedingCheck?.spillageOfBowelContent === "true",
        }),
      },
      intraoperativeComplication: {
        ...(this.intraOperativeComplication?.intraoperative && {
          intraoperative:
            this.intraOperativeComplication?.intraoperative === "true",
        }),
        intraoperativeDetail:
          this.intraOperativeComplication?.intraoperativeDetail,
      },
      otherOperativeDetail: this.operativeNote?.otherOperativeDetail,
      files,
    };
    return body;
  }
  @action
  async onGetOperativeNoteById(id: string) {
    const { data } = await GetOperativeNoteByIdService(id);
    this.operativeNote = {
      id: data?.id || "",
      referenceNo: data?.referenceNo || "",
      operativeDate:
        (data?.operativeInformation?.operativeDate &&
          dayjs(data?.operativeInformation?.operativeDate)) ||
        "",
      operativeSetting: data?.operativeInformation?.operativeSetting || "",
      colorectalSurgery: data?.diagnosis?.colorectalSurgery || {
        ...InitialDropdownConstant,
      },
      emergencyDetail: data?.operativeInformation?.emergencyDetail || {
        ...InitialDropdownConstant,
      },
      synchronousLesionDetail: data?.synchronousLesion?.detail,
      otherOperativeDetail: data?.otherOperativeDetail,
      files:
        data?.files?.map((file: any) => {
          return { ...file, checked: false };
        }) || [],
      createdAt: data?.createdAt || "",
      updatedAt: data?.updatedAt || "",
      deletedAt: data?.deletedAt || "",
    };
    this.diagnosisLocations = this.diagnosisLocations?.map(
      (diagnosisLocation) => {
        return {
          ...diagnosisLocation,
          value:
            (data?.diagnosis && data?.diagnosis[diagnosisLocation.key]) ||
            false,
        };
      }
    );
    this.synchronousLesions = this.synchronousLesions?.map(
      (synchronousLesion) => {
        return {
          ...synchronousLesion,
          value:
            (data?.synchronousLesion &&
              data?.synchronousLesion[synchronousLesion.key]) ||
            false,
        };
      }
    );
    this.surgeons = {
      surgeonOne: (data?.surgeon?.surgeonOne && {
        ...data?.surgeon?.surgeonOne,
        fullName: `${data?.surgeon?.surgeonOne?.firstName} ${data?.surgeon?.surgeonOne?.lastName}`,
      }) || { ...InitialUserConstant },
      surgeonOneType: data?.surgeon?.surgeonOneType || {
        ...InitialDropdownConstant,
      },
      surgeonTwo: (data?.surgeon?.surgeonTwo && {
        ...data?.surgeon?.surgeonTwo,
        fullName: `${data?.surgeon?.surgeonTwo?.firstName} ${data?.surgeon?.surgeonTwo?.lastName}`,
      }) || { ...InitialUserConstant },
      surgeonTwoType: data?.surgeon?.surgeonTwoType || {
        ...InitialDropdownConstant,
      },
      surgeonThree: (data?.surgeon?.surgeonThree && {
        ...data?.surgeon?.surgeonThree,
        fullName: `${data?.surgeon?.surgeonThree?.firstName} ${data?.surgeon?.surgeonThree?.lastName}`,
      }) || { ...InitialUserConstant },
      surgeonThreeType: data?.surgeon?.surgeonThreeType || {
        ...InitialDropdownConstant,
      },
    };
    this.assistants = {
      assistantOne: (data?.surgeon?.assistantOne && {
        ...data?.surgeon?.assistantOne,
        fullName: `${data?.surgeon?.assistantOne?.firstName} ${data?.surgeon?.assistantOne?.lastName}`,
      }) || { ...InitialUserConstant },
      assistantOneType: data?.surgeon?.assistantOneType || {
        ...InitialDropdownConstant,
      },
      assistantTwo: (data?.surgeon?.assistantTwo && {
        ...data?.surgeon?.assistantTwo,
        fullName: `${data?.surgeon?.assistantTwo?.firstName} ${data?.surgeon?.assistantTwo?.lastName}`,
      }) || { ...InitialUserConstant },
      assistantTwoType: data?.surgeon?.assistantTwoType || {
        ...InitialDropdownConstant,
      },
      assistantThree: (data?.surgeon?.assistantThree && {
        ...data?.surgeon?.assistantThree,
        fullName: `${data?.surgeon?.assistantThree?.firstName} ${data?.surgeon?.assistantThree?.lastName}`,
      }) || { ...InitialUserConstant },
      assistantThreeType: data?.surgeon?.assistantThreeType || {
        ...InitialDropdownConstant,
      },
      otherAssistant: data?.surgeon?.otherAssistant || "",
      anesthesiologist: data?.surgeon?.anesthesiologist || "",
      scrubNurse: data?.surgeon?.scrubNurse || "",
      operativeStartAt:
        (data?.surgeon?.operativeStartAt &&
          dayjs(data?.surgeon?.operativeStartAt)) ||
        "",
      operativeStopAt:
        (data?.surgeon?.operativeStopAt &&
          dayjs(data?.surgeon?.operativeStopAt)) ||
        "",
      totalOperativeTime: data?.surgeon?.totalOperativeTime || "",
      bloodLoss:
        ((data?.surgeon?.bloodLoss || data?.surgeon?.bloodLoss === 0) &&
          `${data?.surgeon?.bloodLoss}`) ||
        "",
    };
    this.anesthesia = this.anesthesia.map((anesthesia) => {
      return {
        ...anesthesia,
        value: (data?.surgeon && data?.surgeon[anesthesia.key]) || false,
      };
    });
    this.transfusion = {
      prc: `${data?.transfusion?.prc}` || "",
      ffp: `${data?.transfusion?.ffp}` || "",
      platelet: `${data?.transfusion?.platelet}`,
    };
    this.operativeProcedures = this.operativeProcedures?.map(
      (operativeProcedure) => {
        return {
          ...operativeProcedure,
          value: data?.operation[operativeProcedure.key] || false,
        };
      }
    );
    this.operation = {
      abdominoperinealResectionValue: data?.operation
        ?.abdominoperinealResectionValue || { ...InitialDropdownConstant },
      otherProcedureDetail: data?.operation?.otherProcedureDetail || "",
    };
    this.pelvicExenteration = this.pelvicExenteration?.map(
      (pelvicExenteration) => {
        return {
          ...pelvicExenteration,
          value: data?.operation[pelvicExenteration.key] || false,
        };
      }
    );
    this.sacrectomy = this.sacrectomy?.map((sacrectomy) => {
      return {
        ...sacrectomy,
        value: data?.operation[sacrectomy.key] || false,
      };
    });
    this.sacrectomyExtended = this.sacrectomyExtended?.map(
      (sacrectomyExtended) => {
        return {
          ...sacrectomyExtended,
          value: data?.operation[sacrectomyExtended.key] || false,
        };
      }
    );
    this.position = this.position?.map((position) => {
      return {
        ...position,
        value: data?.operation[position.key] || false,
      };
    });
    this.technique = this.technique?.map((technique) => {
      return {
        ...technique,
        value: data?.operation[technique.key] || false,
      };
    });
    this.conversionToOpen = this.conversionToOpen?.map((conversionToOpen) => {
      return {
        ...conversionToOpen,
        value: data?.operation[conversionToOpen.key] || false,
      };
    });
    this.malignancyCase = {
      malignancyCase:
        (data?.malignancyCase?.malignancyCase &&
          `${data?.malignancyCase?.malignancyCase}`) ||
        "",
    };
    this.malignancyCaseTreatmentIntention =
      this.malignancyCaseTreatmentIntention?.map(
        (malignancyCaseTreatmentIntention) => {
          return {
            ...malignancyCaseTreatmentIntention,
            value:
              data?.malignancyCase[malignancyCaseTreatmentIntention.key] ||
              false,
          };
        }
      );
    this.malignantFinding = {
      liverMetastasis: `${data?.malignantFinding?.liverMetastasis}` || "",
      ascites: `${data?.malignantFinding?.ascites}` || "",
      ascitesAmount:
        ((data?.malignantFinding?.ascitesAmount ||
          data?.malignantFinding?.ascitesAmount === 0) &&
          `${data?.malignantFinding?.ascitesAmount}`) ||
        "",
      peritonealSeeding: `${data?.malignantFinding?.peritonealSeeding}` || "",
      otherQuadrantsDetail: data?.malignantFinding?.otherQuadrantsDetail || "",
    };
    this.liverMetastatectomy = this.liverMetastatectomy?.map(
      (liverMetastatectomy) => {
        return {
          ...liverMetastatectomy,
          value: data.malignantFinding[liverMetastatectomy.key] || false,
        };
      }
    );
    this.seedingSite = this.seedingSite?.map((seedingSite) => {
      return {
        ...seedingSite,
        value: data?.malignantFinding[seedingSite.key] || false,
      };
    });
    this.resection = this.resection?.map((resection) => {
      return {
        ...resection,
        value: data?.resection[resection.key],
      };
    });
    this.colonicResection = {
      completeMesocolicExcision:
        `${data?.resection?.colonicResectionValue?.completeMesocolicExcision}` ||
        "",
      qualityOfCmeSpecimen:
        `${data?.resection?.colonicResectionValue?.qualityOfCmeSpecimen}` || "",
    };
    this.middleColicArtery = this.middleColicArtery?.map(
      (middleColicArtery) => {
        return {
          ...middleColicArtery,
          value:
            data?.resection?.colonicResectionValue[middleColicArtery.key] ||
            false,
        };
      }
    );
    this.rectalResection = {
      totalMesorectalExcision: data?.resection?.rectalResectionValue
        ?.totalMesorectalExcision || { ...InitialDropdownConstant },
      splenicFlexureMobilization:
        `${data?.resection?.rectalResectionValue?.splenicFlexureMobilization}` ||
        "",
      dissectionPlaneOfCircumferential: data?.resection?.rectalResectionValue
        ?.dissectionPlaneOfCircumferential || { ...InitialDropdownConstant },
      qualityOfTmeSpecimen:
        data?.resection?.rectalResectionValue?.qualityOfTmeSpecimen || "",
      pelvicAutonomicNerve: data?.resection?.rectalResectionValue
        ?.pelvicAutonomicNerve || { ...InitialDropdownConstant },
      pelvicAutonomicNerveDetail:
        data?.resection?.rectalResectionValue?.pelvicAutonomicNerveDetail || "",
    };
    this.pelvicLymphNodeDissection = this.pelvicLymphNodeDissection?.map(
      (pelvicLymphNodeDissection) => {
        return {
          ...pelvicLymphNodeDissection,
          value:
            data?.resection?.rectalResectionValue[
              pelvicLymphNodeDissection.key
            ] || false,
        };
      }
    );
    this.iliacVesselsResection = this.iliacVesselsResection?.map(
      (iliacVesselsResection) => {
        return {
          ...iliacVesselsResection,
          value:
            data?.resection?.rectalResectionValue[iliacVesselsResection.key] ||
            false,
        };
      }
    );
    this.ligation = {
      centralVascularLigation:
        `${data?.ligation?.centralVascularLigation}` || "",
      imaLigation: data?.ligation?.imaLigation || {
        ...InitialDropdownConstant,
      },
      imvLigation: data?.ligation?.imvLigation || {
        ...InitialDropdownConstant,
      },
      staplerValue: data?.ligation?.staplerValue || "",
      staplerPiece: data?.ligation?.staplerPiece || 0,
    };
    this.detailOfVascularLigations = this.detailOfVascularLigations?.map(
      (detailOfVascularLigations) => {
        return {
          ...detailOfVascularLigations,
          value: data?.ligation[detailOfVascularLigations.key] || false,
        };
      }
    );
    this.rectalTransactions = this.rectalTransactions?.map(
      (rectalTransactions) => {
        return {
          ...rectalTransactions,
          value: data?.ligation[rectalTransactions.key] || false,
        };
      }
    );
    this.isr = this.isr?.map((isr) => {
      return {
        ...isr,
        value: data?.ligation[isr.key] || false,
      };
    });
    this.levelOfSphincterExcision = this.levelOfSphincterExcision?.map(
      (levelOfSphincterExcision) => {
        return {
          ...levelOfSphincterExcision,
          value: data?.ligation[levelOfSphincterExcision.key] || false,
        };
      }
    );
    this.margin = {
      distalMargin: data?.margin?.distalMargin || 0,
      proximalMargin: data?.margin?.proximalMargin || 0,
      grosslyMargin: data?.margin?.grosslyMargin || {
        ...InitialDropdownConstant,
      },
    };
    this.organResection = {
      adjacentOrgansInvasion:
        `${data?.organsResection?.adjacentOrgansInvasion}` || "",
      otherOrganDetail: data?.organsResection?.otherOrganDetail || "",
      vaginalReconstruction: data?.organsResection?.vaginalReconstruction || {
        ...InitialDropdownConstant,
      },
      vaginalReconstructionDetail:
        data?.organsResection?.vaginalReconstructionDetail || "",
      palvicFloorDefectReconstruction: data?.organsResection
        ?.palvicFloorDefectReconstruction || { ...InitialDropdownConstant },
      palvicFloorDefectReconstructionDetail:
        data?.organsResection?.palvicFloorDefectReconstructionDetail || "",
      closureOfPerinealWound: data?.organsResection?.closureOfPerinealWound || {
        ...InitialDropdownConstant,
      },
      closureOfPerinealWoundDetail:
        data?.organsResection?.closureOfPerinealWoundDetail || "",
    };
    this.enBlocResectionOfAdjacentOrgans =
      this.enBlocResectionOfAdjacentOrgans?.map(
        (enBlocResectionOfAdjacentOrgans) => {
          return {
            ...enBlocResectionOfAdjacentOrgans,
            value:
              data?.organsResection[enBlocResectionOfAdjacentOrgans.key] ||
              false,
          };
        }
      );
    this.diaphragms = this.diaphragms?.map((diaphragm) => {
      return {
        ...diaphragm,
        value: data?.organsResection[diaphragm.key] || false,
      };
    });
    this.stomachs = this.stomachs?.map((stomach) => {
      return {
        ...stomach,
        value: data?.organsResection[stomach.key] || false,
      };
    });
    this.duodenums = this.duodenums?.map((duodenum) => {
      return {
        ...duodenum,
        value: data?.organsResection[duodenum.key] || false,
      };
    });
    this.smallIntestines = this.smallIntestines?.map((smallIntestine) => {
      return {
        ...smallIntestine,
        value: data?.organsResection[smallIntestine.key] || false,
      };
    });
    this.pancreases = this.pancreases?.map((pancreas) => {
      return {
        ...pancreas,
        value: data?.organsResection[pancreas.key] || false,
      };
    });
    this.kidney = this.kidney?.map((kidney) => {
      return {
        ...kidney,
        value: data?.organsResection[kidney.key] || false,
      };
    });
    this.adrenalGlands = this.adrenalGlands?.map((adrenalGland) => {
      return {
        ...adrenalGland,
        value: data?.organsResection[adrenalGland.key] || false,
      };
    });
    this.ureters = this.ureters?.map((ureter) => {
      return {
        ...ureter,
        value: data?.organsResection[ureter.key] || false,
      };
    });
    this.urinaryBladders = this.urinaryBladders?.map((urinaryBladder) => {
      return {
        ...urinaryBladder,
        value: data?.organsResection[urinaryBladder.key] || false,
      };
    });
    this.uteruses = this.uteruses?.map((uterus) => {
      return {
        ...uterus,
        value: data?.organsResection[uterus.key] || false,
      };
    });
    this.ovaries = this.ovaries?.map((ovary) => {
      return {
        ...ovary,
        value: data?.organsResection[ovary.key] || false,
      };
    });
    this.vaginas = this.vaginas?.map((vagina) => {
      return {
        ...vagina,
        value: data?.organsResection[vagina.key] || false,
      };
    });
    this.prostates = this.prostates?.map((prostate) => {
      return {
        ...prostate,
        value: data?.organsResection[prostate.key] || false,
      };
    });
    this.pelvicFloors = this.pelvicFloors?.map((pelvicFloor) => {
      return {
        ...pelvicFloor,
        value: data?.organsResection[pelvicFloor.key] || false,
      };
    });
    this.organResectionSacrectomy = this.organResectionSacrectomy?.map(
      (organResectionSacrectomy) => {
        return {
          ...organResectionSacrectomy,
          value: data?.organsResection[organResectionSacrectomy.key] || false,
        };
      }
    );
    this.urinaryBladderReconstructionDetails =
      this.urinaryBladderReconstructionDetails?.map(
        (urinaryBladderReconstructionDetail) => {
          return {
            ...urinaryBladderReconstructionDetail,
            value:
              data?.organsResection[urinaryBladderReconstructionDetail.key] ||
              false,
          };
        }
      );
    this.anastomosisSection = {
      linearStaple: data?.anastomosisSection?.linearStaple,
      circularStaple: data?.anastomosisSection?.circularStaple || {
        ...InitialDropdownConstant,
      },
      repairBy: data?.anastomosisSection?.repairBy || {
        ...InitialDropdownConstant,
      },
      repairByDetail: data?.anastomosisSection?.repairByDetail || "",
    };
    this.anastomoticTypes = this.anastomoticTypes?.map((anastomoticType) => {
      return {
        ...anastomoticType,
        value: data?.anastomosisSection[anastomoticType.key] || false,
      };
    });
    this.anastomoticTechniques = this.anastomoticTechniques?.map(
      (anastomoticTechnique) => {
        return {
          ...anastomoticTechnique,
          value: data?.anastomosisSection[anastomoticTechnique.key] || false,
        };
      }
    );
    this.anastomosis = this.anastomosis?.map((anastomosis) => {
      return {
        ...anastomosis,
        value: data?.anastomosisSection[anastomosis.key] || false,
      };
    });
    this.distalRectalWashouts = this.distalRectalWashouts?.map(
      (distalRectalWashout) => {
        return {
          ...distalRectalWashout,
          value: data?.anastomosisSection[distalRectalWashout.key] || false,
        };
      }
    );
    this.airLeakTests = this.airLeakTests?.map((airLeakTest) => {
      return {
        ...airLeakTest,
        value: data?.anastomosisSection[airLeakTest.key] || false,
      };
    });
    this.ostomy = {
      ostomy: data?.ostomy?.ostomy || false,
      option: data?.ostomy?.ostomyOption,
    };
    this.ostomySites = this.ostomySites?.map((ostomySite) => {
      return {
        ...ostomySite,
        value: data?.ostomy[ostomySite.key] || false,
      };
    });
    this.ostomyTypes = this.ostomyTypes?.map((ostomyType) => {
      return {
        ...ostomyType,
        value: data?.ostomy[ostomyType.key] || false,
      };
    });
    this.ostomyOpenings = this.ostomyOpenings?.map((ostomyOpening) => {
      return {
        ...ostomyOpening,
        value: data?.ostomy[ostomyOpening.key] || false,
      };
    });
    this.ostomyMaturations = this.ostomyMaturations?.map((ostomyMaturation) => {
      return {
        ...ostomyMaturation,
        value: data?.ostomy[ostomyMaturation.key] || false,
      };
    });
    this.woundAndDrainage = {
      abdominalCavityDrainage:
        data?.woundAndDrainage?.abdominalCavityDrainage || "",
      abdominalCavityDrainageDetail:
        data?.woundAndDrainage?.abdominalCavityDrainageDetail || "",
      perinealWoundDrainage:
        data?.woundAndDrainage?.perinealWoundDrainage || "",
      perinealWoundDrainageDetail:
        data?.woundAndDrainage?.perinealWoundDrainageDetail || "",
    };
    this.bleedingCheck = {
      bleedingCheck: data?.bleedingCheck?.bleedingCheck || false,
      stoppedAt: data?.bleedingCheck?.stoppedAt || "",
      rectalTubeInsertion: `${data?.bleedingCheck?.rectalTubeInsertion}` || "",
      spillageOfBowelContent:
        `${data?.bleedingCheck?.spillageOfBowelContent}` || "",
    };
    this.surgicalWoundClassifications = this.surgicalWoundClassifications?.map(
      (surgicalWoundClassification) => {
        return {
          ...surgicalWoundClassification,
          value: data?.bleedingCheck[surgicalWoundClassification.key] || false,
        };
      }
    );
    this.intraOperativeComplication = {
      intraoperative:
        `${data?.intraoperativeComplication?.intraoperative}` || "",
      intraoperativeDetail:
        data?.intraoperativeComplication?.intraoperativeDetail || "",
    };
  }
}

export default OperativeNoteStore;
