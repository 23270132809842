export const TissuePathologyKeys = {
  none: "none",
  adenocarcinoma: "adenocarcinoma",
  squamousCellCa: "squamousCellCa",
  gist: "gist",
  neuroendocrineTumor: "neuroendocrineTumor",
  lymphoma: "lymphoma",
  other: "other",
};
export const TissuePathologyOptions = [
  {
    label: "None",
    value: false,
    key: TissuePathologyKeys.none,
  },
  {
    label: "Adenocarcinoma",
    value: false,
    key: TissuePathologyKeys.adenocarcinoma,
  },
  {
    label: "Squamous cell CA",
    value: false,
    key: TissuePathologyKeys.squamousCellCa,
  },
  {
    label: "GIST",
    value: false,
    key: TissuePathologyKeys.gist,
  },
  {
    label: "Neuroendocrine tumor",
    value: false,
    key: TissuePathologyKeys.neuroendocrineTumor,
  },
  {
    label: "Lymphoma",
    value: false,
    key: TissuePathologyKeys.lymphoma,
  },
  {
    label: "Other",
    value: false,
    key: TissuePathologyKeys.other,
  },
];

export const AdenocarcinomaAndSquamousDifferentiationEnum = {
  well: "WELL",
  moderate: "MODERATE",
  poor: "POOR",
};
export const AdenocarcinomaAndSquamousDifferentiationOptions = [
  {
    label: "Well",
    value: AdenocarcinomaAndSquamousDifferentiationEnum.well,
  },
  {
    label: "Moderate",
    value: AdenocarcinomaAndSquamousDifferentiationEnum.moderate,
  },
  {
    label: "Poor",
    value: AdenocarcinomaAndSquamousDifferentiationEnum.poor,
  },
];

export const ProcedureKeys = {
  none: "none",
  colonicStent: "colonicStent",
  chemotherapy: "chemotherapy",
  radiation: "radiation",
  chemoradiation: "chemoradiation",
};
export const ProcedureOptions = [
  {
    label: "None",
    value: false,
    key: ProcedureKeys.none,
  },
  {
    label: "Colonic stent",
    value: false,
    key: ProcedureKeys.colonicStent,
  },
  {
    label: "Chemotherapy",
    value: false,
    key: ProcedureKeys.chemotherapy,
  },
  {
    label: "Radiation",
    value: false,
    key: ProcedureKeys.radiation,
  },
  {
    label: "Chemoradiation",
    value: false,
    key: ProcedureKeys.chemoradiation,
  },
];

export const NeoadjuvantChemoRegimenEnum = {
  none: "NONE",
  fulv: "5FU_LV",
  xeloxAv: "XELOX_AV",
  folfox: "FOLFOX",
  xeliri: "XELIRI",
  xeliriErbitux: "XELIRI_ERBITUX",
  xelodaMono: "XELODA_MONO",
  xelox: "XELOX",
  xeloxErbitux: "XELOX_ERBITUX",
  fofloxAv: "FOLFOX_AV",
  folfiri: "FOLFIRI",
  folfiriErbitux: "FOLFIRI_ERBITUX",
};
export const NeoadjuvantChemoRegimenOptions = [
  {
    label: "None",
    value: NeoadjuvantChemoRegimenEnum.none,
  },
  {
    label: "Xeloda mono",
    value: NeoadjuvantChemoRegimenEnum.xelodaMono,
  },
  {
    label: "5FU + LV (Mayo)",
    value: NeoadjuvantChemoRegimenEnum.fulv,
  },
  {
    label: "XELOX",
    value: NeoadjuvantChemoRegimenEnum.xelox,
  },
  {
    label: "XELOX + AV",
    value: NeoadjuvantChemoRegimenEnum.xeloxAv,
  },
  {
    label: "XELOX + Erbitux",
    value: NeoadjuvantChemoRegimenEnum.xeloxErbitux,
  },
  {
    label: "FOLFOX",
    value: NeoadjuvantChemoRegimenEnum.folfox,
  },
  {
    label: "FOLFOX + AV",
    value: NeoadjuvantChemoRegimenEnum.fofloxAv,
  },
  {
    label: "XELIRI",
    value: NeoadjuvantChemoRegimenEnum.xeliri,
  },
  {
    label: "FOLFIRI",
    value: NeoadjuvantChemoRegimenEnum.folfiri,
  },
  {
    label: "XELIRI + Erbitux",
    value: NeoadjuvantChemoRegimenEnum.xeliri,
  },
  {
    label: "FOLFIRI + Erbitux",
    value: NeoadjuvantChemoRegimenEnum.folfiriErbitux,
  },
];

export const PreoperativeStagingByKeys = {
  cxr: "cxr",
  usg: "usg",
  ctScan: "ctScan",
  mri: "mri",
  petScan: "petScan",
  erus: "erus",
};
export const PreoperativeStagingByOptions = [
  {
    label: "CXR",
    value: false,
    key: PreoperativeStagingByKeys.cxr,
  },
  {
    label: "USG",
    value: false,
    key: PreoperativeStagingByKeys.usg,
  },
  {
    label: "CT scan",
    value: false,
    key: PreoperativeStagingByKeys.ctScan,
  },
  {
    label: "MRI",
    value: false,
    key: PreoperativeStagingByKeys.mri,
  },
  {
    label: "PET Scan",
    value: false,
    key: PreoperativeStagingByKeys.petScan,
  },
  {
    label: "ERUS",
    value: false,
    key: PreoperativeStagingByKeys.erus,
  },
];

export const StatusEnum = { normal: "NORMAL", hideCancel: "HIDE_CANCEL" };
export const StatusOptions = [
  { label: "---- ตัวเลือก ----", value: null },
  { label: "Normal", value: StatusEnum.normal },
  {
    label: "Hide/Cancel",
    value: StatusEnum.hideCancel,
  },
];
