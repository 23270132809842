export const OstomyOptionEnum = {
  new: "NEW",
  previous: "PREVIOUS",
};
export const OstomyOptions = [
  {
    label: "New ostomy",
    value: OstomyOptionEnum.new,
  },
  {
    label: "Previous ostomy",
    value: OstomyOptionEnum.previous,
  },
];

export const OstomySiteKey = {
  ruq: "ruq",
  rlq: "rlq",
  luq: "luq",
  llq: "llq",
};
export const OstomySiteOptions = [
  {
    label: "RUQ",
    value: false,
    key: OstomySiteKey.ruq,
  },
  {
    label: "RLQ",
    value: false,
    key: OstomySiteKey.rlq,
  },
  {
    label: "LUQ",
    value: false,
    key: OstomySiteKey.luq,
  },
  {
    label: "LLQ",
    value: false,
    key: OstomySiteKey.llq,
  },
];

export const OstomyTypeKey = {
  ileostomy: "ileostomy",
  colostomy: "colostomy",
};
export const OstomyTypeOptions = [
  {
    label: "Ileostomy",
    value: false,
    key: OstomyTypeKey.ileostomy,
  },
  {
    label: "Colostomy",
    value: false,
    key: OstomyTypeKey.colostomy,
  },
];

export const OstomyOpeningKey = {
  loop: "loop",
  end: "end",
};
export const OstomyOpeningOptions = [
  {
    label: "Loop",
    value: false,
    key: OstomyOpeningKey.loop,
  },
  {
    label: "End",
    value: false,
    key: OstomyOpeningKey.end,
  },
];

export const OstomyMaturationsKey = {
  simple: "simple",
  extraperitoneum: "extraperitoneum",
};
export const OstomyMaturationsOptions = [
  {
    label: "Simple",
    value: false,
    key: OstomyMaturationsKey.simple,
  },
  {
    label: "Extraperitoneum",
    value: false,
    key: OstomyMaturationsKey.extraperitoneum,
  },
];
