import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Grid, Box, Typography, Stack } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { useStores } from "../../../stores";
import { ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant } from "../../../constants/Form";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import {
  QualityOfCmeSpecimenEnum as QualityOfCmeSpecimenEnumConstant,
  ResectionKey as ResectionKeyConstant,
} from "../../../constants/colorectalSurgery/operativeNotes/Resection";
import { DropdownTypes as DropdownTypesConstant } from "../../../constants/Dropdown";
import { Dropdown as DropdownModel } from "../../../models/Dropdown";
import { GetDropdownByType as GetDropdownByTypeService } from "../../../services/Dropdown";
import CheckboxGroupSelection from "../../inputs/CheckboxGroupSelection";
import RadioSelection from "../../inputs/RadioSelection";
import DropdownSelection from "../../dropdowns/Selection";
import InputTextField from "../../inputs/TextField";

interface Props {}

const FormResection = observer((props: Props) => {
  const { OperativeNoteStore } = useStores();
  const [totalMesorectalExcisions, setTotalMesorectalExcisions] = useState<
    DropdownModel[]
  >([]);
  const [
    dissectionPlaneOfCircumferential,
    setDissectionPlaneOfCircumferential,
  ] = useState<DropdownModel[]>([]);
  const [pelvicAutonomicNerves, setPelvicAutonomicNerves] = useState<
    DropdownModel[]
  >([]);

  useEffect(() => {
    if (
      !OperativeNoteStore.isReadOnly &&
      OperativeNoteStore.resection[1] &&
      OperativeNoteStore.resection[1].value
    ) {
      !totalMesorectalExcisions?.length &&
        getDropdownTotalMesorectalExcisions();
      !dissectionPlaneOfCircumferential?.length &&
        getDropdownDissectionPlaneOfCircumferential();
      !pelvicAutonomicNerves.length && getDropdownPelvicAutonomicNerves();
    }
  }, [
    OperativeNoteStore.resection,
    OperativeNoteStore.isReadOnly,
    dissectionPlaneOfCircumferential,
    pelvicAutonomicNerves,
    totalMesorectalExcisions,
  ]);

  const getDropdownTotalMesorectalExcisions = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.operativeNoteTotalMesorectalExcision
    );
    response.success && setTotalMesorectalExcisions([...response.data]);
  };
  const getDropdownDissectionPlaneOfCircumferential = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.operativeNoteDissectionPlane
    );
    response.success && setDissectionPlaneOfCircumferential([...response.data]);
  };
  const getDropdownPelvicAutonomicNerves = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.operativeNotePelvicAutonomicNerve
    );
    response.success && setPelvicAutonomicNerves([...response.data]);
  };
  const onInputResectionChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.resection];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetResection([...forms]);
    forms[index]?.key === ResectionKeyConstant.colonicResection &&
      !value &&
      OperativeNoteStore.onClearColonicResection();
    if (forms[index]?.key === ResectionKeyConstant.rectalResection) {
      if (value) {
        !totalMesorectalExcisions?.length &&
          getDropdownTotalMesorectalExcisions();
        !dissectionPlaneOfCircumferential?.length &&
          getDropdownDissectionPlaneOfCircumferential();
        !pelvicAutonomicNerves.length && getDropdownPelvicAutonomicNerves();
      } else {
        OperativeNoteStore.onClearRectalResection();
      }
    }
  };
  const onInputColonicResectionChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.colonicResection };
    form[key] = value;
    OperativeNoteStore.onSetColonicResection({ ...form });
  };
  const onInputMiddleColicArteryChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.middleColicArtery];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetMiddleColicArtery([...forms]);
  };
  const onInputRectalResectionChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.rectalResection };
    const formIsInvalid: any = {
      ...OperativeNoteStore.rectalResectionIsInvalid,
    };
    form[key] = value;
    if (key === "splenicFlexureMobilization")
      formIsInvalid.splenicFlexureMobilization = false;
    OperativeNoteStore.onSetRectalResectionIsInvalid({ ...formIsInvalid });
    OperativeNoteStore.onSetRectalResection({ ...form });
  };
  const onInputPelvicLymphNodeDissectionChange = (
    index: number,
    value: boolean
  ) => {
    const forms: any = [...OperativeNoteStore.pelvicLymphNodeDissection];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetPelvicLymphNodeDissection([...forms]);
  };
  const onInputIliacVesselsResectionChange = (
    index: number,
    value: boolean
  ) => {
    const forms: any = [...OperativeNoteStore.iliacVesselsResection];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetIliacVesselsResection([...forms]);
  };

  return (
    <>
      <Box
        id={ColorectalSurgeryOperativeNoteFormConstant.resection}
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
        color={ColorsConstant.black}
      >
        <Typography variant="h6">Resection</Typography>
      </Box>
      {/* Resection */}
      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={12} md={5} marginTop={1}>
          <CheckboxGroupSelection
            options={OperativeNoteStore.resection}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={6}
            gridColumnLg={6}
            spacing={1}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputResectionChange(index, value)
            }
          />
        </Grid>
      </Grid>
      {/* /.Resection */}

      {/* Splenic flexure mobilization */}
      <Box marginTop={3}>
        <Box className="input-label">
          <Typography variant="body2">Splenic flexure mobilization</Typography>
          <Typography className="input-label-require" variant="body2">
            &nbsp;*&nbsp;
          </Typography>
          <Typography variant="body2">:</Typography>
        </Box>
        <Box display="flex" marginTop={3}>
          <RadioSelection
            value="true"
            selectedValue={
              OperativeNoteStore.rectalResection?.splenicFlexureMobilization ||
              ""
            }
            label="Yes"
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(value: any) =>
              onInputRectalResectionChange("splenicFlexureMobilization", value)
            }
          />
          <RadioSelection
            value="false"
            selectedValue={
              OperativeNoteStore.rectalResection?.splenicFlexureMobilization ||
              ""
            }
            label="No"
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(value: any) =>
              onInputRectalResectionChange("splenicFlexureMobilization", value)
            }
          />
        </Box>
        {OperativeNoteStore.rectalResectionIsInvalid
          ?.splenicFlexureMobilization && (
          <Stack
            className="error-message"
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            marginTop={0.5}
          >
            <ErrorIcon sx={{ fontSize: 16 }} />
            <Typography variant="subtitle2">
              Select a splenic flexure mobilization
            </Typography>
          </Stack>
        )}
      </Box>
      {/* /.Splenic flexure mobilization */}

      {/* Colonic resection */}
      {OperativeNoteStore.resection[0]?.value && (
        <>
          <Box
            paddingBottom={1}
            borderBottom="1px solid"
            borderColor={ColorsConstant.border}
            color={ColorsConstant.black}
            marginTop={3}
          >
            <Typography variant="h6"></Typography>
          </Box>
          <Grid container spacing={2} marginTop={1}>
            {/* Complete mesocolic excision */}
            <Grid item xs={12} md={6}>
              <Box className="input-label">
                <Typography variant="body2">
                  Complete mesocolic excision (CME) :
                </Typography>
              </Box>
              <Box display="flex" marginTop={1}>
                <RadioSelection
                  value="true"
                  selectedValue={
                    OperativeNoteStore.colonicResection
                      ?.completeMesocolicExcision || ""
                  }
                  label="Yes"
                  isDisabled={OperativeNoteStore.isReadOnly}
                  onChange={(value: any) =>
                    onInputColonicResectionChange(
                      "completeMesocolicExcision",
                      value
                    )
                  }
                />
                <RadioSelection
                  value="false"
                  selectedValue={
                    OperativeNoteStore.colonicResection
                      ?.completeMesocolicExcision || ""
                  }
                  label="No"
                  isDisabled={OperativeNoteStore.isReadOnly}
                  onChange={(value: any) =>
                    onInputColonicResectionChange(
                      "completeMesocolicExcision",
                      value
                    )
                  }
                />
              </Box>
            </Grid>
            {/* /.Complete mesocolic excision */}

            {/* Quality of CME specimen */}
            <Grid item xs={12} md={6} marginTop={1}>
              <Box className="input-label">
                <Typography variant="body2">
                  Quality of CME specimen :
                </Typography>
              </Box>
              <Box display="flex" marginTop={1}>
                <RadioSelection
                  value={QualityOfCmeSpecimenEnumConstant.complete}
                  selectedValue={
                    OperativeNoteStore.colonicResection?.qualityOfCmeSpecimen ||
                    ""
                  }
                  label="Complete"
                  isDisabled={OperativeNoteStore.isReadOnly}
                  onChange={(value: any) =>
                    onInputColonicResectionChange("qualityOfCmeSpecimen", value)
                  }
                />
                <RadioSelection
                  value={QualityOfCmeSpecimenEnumConstant.nearlyComplete}
                  selectedValue={
                    OperativeNoteStore.colonicResection?.qualityOfCmeSpecimen ||
                    ""
                  }
                  label="Nearly complete"
                  isDisabled={OperativeNoteStore.isReadOnly}
                  onChange={(value: any) =>
                    onInputColonicResectionChange("qualityOfCmeSpecimen", value)
                  }
                />
                <RadioSelection
                  value={QualityOfCmeSpecimenEnumConstant.incomplete}
                  selectedValue={
                    OperativeNoteStore.colonicResection?.qualityOfCmeSpecimen ||
                    ""
                  }
                  label="Incomplete"
                  isDisabled={OperativeNoteStore.isReadOnly}
                  onChange={(value: any) =>
                    onInputColonicResectionChange("qualityOfCmeSpecimen", value)
                  }
                />
              </Box>
            </Grid>
            {/* /.Quality of CME specimen */}
          </Grid>
          {/* Middle colic artery */}
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} md={6} marginTop={1}>
              <Box className="input-label">
                <Typography variant="body2">Middle colic artery :</Typography>
              </Box>
              <CheckboxGroupSelection
                options={OperativeNoteStore.middleColicArtery}
                labelKey="label"
                valueKey="value"
                gridColumnXs={12}
                gridColumnMd={4}
                gridColumnLg={4}
                spacing={1}
                isDisabled={OperativeNoteStore.isReadOnly}
                onChange={(index: number, value: boolean) =>
                  onInputMiddleColicArteryChange(index, value)
                }
              />
            </Grid>
          </Grid>
          {/* /.Middle colic artery */}
        </>
      )}
      {/* /.Rectal transection */}
      {OperativeNoteStore.resection[1]?.value && (
        <>
          <Box
            paddingBottom={1}
            borderBottom="1px solid"
            borderColor={ColorsConstant.border}
            color={ColorsConstant.black}
            marginTop={3}
          >
            <Typography variant="h6">Rectal Transection</Typography>
          </Box>
          <Grid container spacing={2} marginTop={1}>
            {/* Total mesorectal excision (TME) */}
            <Grid item xs={12} md={6} marginTop={1}>
              <Box className="input-label">
                <Typography variant="body2">
                  Total mesorectal excision (TME) :
                </Typography>
              </Box>
              <DropdownSelection
                options={totalMesorectalExcisions}
                selected={
                  OperativeNoteStore.rectalResection?.totalMesorectalExcision
                }
                optionKey="value"
                valueKey="id"
                placeholder="Total mesorectal excision (TME)"
                isDisabled={OperativeNoteStore.isReadOnly}
                isReadOnly={OperativeNoteStore.isReadOnly}
                onSelected={(value: any) =>
                  onInputRectalResectionChange("totalMesorectalExcision", value)
                }
              />
            </Grid>
            {/* /.Total mesorectal excision (TME) */}
          </Grid>
          <Grid container spacing={2} marginTop={1}>
            {/* Dissection plane of circumferential */}
            <Grid item xs={12} md={6} marginTop={1}>
              <Box className="input-label">
                <Typography variant="body2">
                  Dissection plane of circumferential :
                </Typography>
              </Box>
              <DropdownSelection
                options={dissectionPlaneOfCircumferential}
                selected={
                  OperativeNoteStore.rectalResection
                    ?.dissectionPlaneOfCircumferential
                }
                optionKey="value"
                valueKey="id"
                placeholder="Dissection plane of circumferential"
                isDisabled={OperativeNoteStore.isReadOnly}
                isReadOnly={OperativeNoteStore.isReadOnly}
                onSelected={(value: any) =>
                  onInputRectalResectionChange(
                    "dissectionPlaneOfCircumferential",
                    value
                  )
                }
              />
            </Grid>
            {/* /.Dissection plane of circumferential */}

            {/* Quality of TME specimen */}
            <Grid item xs={12} md={6} marginTop={1}>
              <Box className="input-label">
                <Typography variant="body2">
                  Quality of TME specimen :
                </Typography>
              </Box>
              <Box display="flex" marginTop={3}>
                <RadioSelection
                  value={QualityOfCmeSpecimenEnumConstant.complete}
                  selectedValue={
                    OperativeNoteStore.rectalResection?.qualityOfTmeSpecimen ||
                    ""
                  }
                  label="Complete"
                  isDisabled={OperativeNoteStore.isReadOnly}
                  onChange={(value: any) =>
                    onInputRectalResectionChange("qualityOfTmeSpecimen", value)
                  }
                />
                <RadioSelection
                  value={QualityOfCmeSpecimenEnumConstant.nearlyComplete}
                  selectedValue={
                    OperativeNoteStore.rectalResection?.qualityOfTmeSpecimen ||
                    ""
                  }
                  label="Nearly complete"
                  isDisabled={OperativeNoteStore.isReadOnly}
                  onChange={(value: any) =>
                    onInputRectalResectionChange("qualityOfTmeSpecimen", value)
                  }
                />
                <RadioSelection
                  value={QualityOfCmeSpecimenEnumConstant.incomplete}
                  selectedValue={
                    OperativeNoteStore.rectalResection?.qualityOfTmeSpecimen ||
                    ""
                  }
                  label="Incomplete"
                  isDisabled={OperativeNoteStore.isReadOnly}
                  onChange={(value: any) =>
                    onInputRectalResectionChange("qualityOfTmeSpecimen", value)
                  }
                />
              </Box>
            </Grid>
            {/* /.Quality of TME specimen */}
          </Grid>
          <Grid container spacing={2} marginTop={1}>
            {/* Pelvic lymph node dissection */}
            <Grid item xs={12} md={6} marginTop={1}>
              <Box className="input-label">
                <Typography variant="body2">
                  Pelvic lymph node dissection :
                </Typography>
              </Box>
              <CheckboxGroupSelection
                options={OperativeNoteStore.pelvicLymphNodeDissection}
                labelKey="label"
                valueKey="value"
                gridColumnXs={12}
                gridColumnMd={4}
                gridColumnLg={4}
                spacing={1}
                isDisabled={OperativeNoteStore.isReadOnly}
                onChange={(index: number, value: boolean) =>
                  onInputPelvicLymphNodeDissectionChange(index, value)
                }
              />
            </Grid>
            {/* /.Pelvic lymph node dissection */}

            {/* Iliac vessels resection */}
            <Grid item xs={12} md={6} marginTop={1}>
              <Box className="input-label">
                <Typography variant="body2">
                  Iliac vessels resection :
                </Typography>
              </Box>
              <CheckboxGroupSelection
                options={OperativeNoteStore.iliacVesselsResection}
                labelKey="label"
                valueKey="value"
                gridColumnXs={12}
                gridColumnMd={4}
                gridColumnLg={4}
                spacing={1}
                isDisabled={OperativeNoteStore.isReadOnly}
                onChange={(index: number, value: boolean) =>
                  onInputIliacVesselsResectionChange(index, value)
                }
              />
            </Grid>
            {/* /.PIliac vessels resection */}
          </Grid>
          <Grid container spacing={2} marginTop={1}>
            {/* Pelvic autonomic nerve */}
            <Grid item xs={12} md={6} marginTop={1}>
              <Box className="input-label">
                <Typography variant="body2">
                  Pelvic autonomic nerve :
                </Typography>
              </Box>
              <DropdownSelection
                options={pelvicAutonomicNerves}
                selected={
                  OperativeNoteStore.rectalResection?.pelvicAutonomicNerve
                }
                optionKey="value"
                valueKey="id"
                placeholder="Pelvic autonomic nerve"
                isDisabled={OperativeNoteStore.isReadOnly}
                isReadOnly={OperativeNoteStore.isReadOnly}
                onSelected={(value: any) =>
                  onInputRectalResectionChange("pelvicAutonomicNerve", value)
                }
              />
            </Grid>
            {/* /.Pelvic autonomic nerve */}

            {/* Pelvic autonomic nerve detail */}
            <Grid item xs={12} md={6} marginTop={1}>
              <InputTextField
                id="pelvicAutonomicNerveDetail"
                label="Pelvic autonomic nerve detail"
                placeholder="Pelvic autonomic nerve detail"
                type="text"
                value={
                  OperativeNoteStore.rectalResection?.pelvicAutonomicNerveDetail
                }
                isDisabled={OperativeNoteStore.isReadOnly}
                isReadOnly={OperativeNoteStore.isReadOnly}
                isRequire={false}
                handleOnChange={(value: string) =>
                  onInputRectalResectionChange(
                    "pelvicAutonomicNerveDetail",
                    value
                  )
                }
              />
            </Grid>
            {/* /.Pelvic autonomic nerve detail */}
          </Grid>
        </>
      )}
      {/* /.Rectal transection */}
    </>
  );
});

export default FormResection;
